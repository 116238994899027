import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { LevelOneHelpComponent } from '@app/shared/components/level-one-help/level-one-help.component';
import { CustomToolbarComponent } from './components/custom-toolbar/custom-toolbar.component';
import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';
import { ModalComponent } from './components/modal/modal.component';
import { InsertionDirective } from './components/modal/insertion-directive';
import { DiscardChangesModalComponent } from './components/modal/templates/discard-changes-modal/discard-changes-modal.component';
import { ToasterComponent } from './toaster/toaster.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { ScoresErrorListComponent } from './components/scores-error-list/scores-error-list.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { MarketingPlanItemComponent } from './components/marketing-plan-item/marketing-plan-item.component';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal.directive';
import { EditableTdDirective } from '@app/shared/directives/editable-td-directive';

import { TranslateModule } from '@ngx-translate/core';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent } from './components/stepper/step.component';
import { DropdownButtonComponent } from '@app/shared/components/dropdown-button/dropdown-button.component';
import { DropdownTreeComponent } from '@app/shared/components/dropdown-tree/dropdown-tree.component';
import { NumericDirective } from '@app/shared/directives/numeric.directive';
import { RegexDirective } from './directives/regex.directive';
import { NumberDisplayPipe } from '@app/shared/pipes/number-display.pipe';
import { ReplaceSpacePipe } from '@app/shared/pipes/text-display.pipe';
import { CommaSeparatedNumberDirective } from '@app/shared/directives/comma-separated-number.directive';
import { CustomDropdownComponent } from '@app/shared/components/custom-dropdown/custom-dropdown.component';
import { FloatingSpinnerComponent } from './components/floating-spinner/floating-spinner.component';
import { MultiSelectDropdownComponent } from '@app/shared/components/multi-select-dropdown/multi-select-dropdown.component';
import { ReplaceSpaceCamelCasePipe } from './pipes/replace-space-camel-case.pipe';
import { ArrayFillPipe } from '@app/shared/pipes/array-fill.pipe';
import { OneDigitDecimalDirective } from '@app/shared/directives/one-digit-decimal.directive';
import { BasicForecastInfoComponent } from '@app/shared/components/basic-forecast-info/basic-forecast-info.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { OverrideHistoryModalComponent } from './components/modal/templates/override-history-modal/override-history-modal.component';
import { MultiSelectTreeComponent } from './components/multi-select-tree/multi-select-tree.component';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MarketingPlanMenuPipe } from '../main/marketing-plans/pipes/marketing-plan-menu.pipe';
import { TooltipTextPipe } from '@app/main/marketing-plans/pipes/tooltip-text.pipe';
import { SixDigitDecimalNumberDirective } from './directives/six-digit-decimal.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { ModalConfig } from './components/modal/modal-config';
import { ModalRef } from './components/modal/modal-ref';

const COMPONENTS: Array<any> = [
  LevelOneHelpComponent,
  CustomToolbarComponent,
  CustomDatePickerComponent,
  ToasterComponent,
  ErrorListComponent,
  ScoresErrorListComponent,
  DropdownComponent,
  EditableTdDirective,
  ModalComponent,
  InsertionDirective,
  DiscardChangesModalComponent,
  TwoDigitDecimalNumberDirective,
  OneDigitDecimalDirective,
  NumericDirective,
  RegexDirective,
  MarketingPlanItemComponent,
  StepComponent,
  StepperComponent,
  DropdownButtonComponent,
  DropdownTreeComponent,
  NumberDisplayPipe,
  ReplaceSpacePipe,
  CommaSeparatedNumberDirective,
  CustomDropdownComponent,
  FloatingSpinnerComponent,
  MultiSelectDropdownComponent,
  ArrayFillPipe,
  BasicForecastInfoComponent,
  CarouselComponent,
  MultiSelectTreeComponent,
  SixDigitDecimalNumberDirective,
  ErrorMessageComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    MatTreeModule,
    MatMenuModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    COMPONENTS,
    ReplaceSpacePipe,
    ReplaceSpaceCamelCasePipe,
    OverrideHistoryModalComponent,
    MultiSelectTreeComponent,
    MarketingPlanMenuPipe,
    TooltipTextPipe,
  ],
  providers:[ModalConfig,ModalRef],
  exports: [
    COMPONENTS,
    ReplaceSpacePipe,
    ReplaceSpaceCamelCasePipe,
    MarketingPlanMenuPipe,
    TooltipTextPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ModalComponent, MultiSelectTreeComponent,ToasterComponent],
})
export class SharedModule {}
