import { Injectable, EventEmitter, Injector } from '@angular/core';
import { Toast } from '../toaster/toaster.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '../toaster/toaster.component';
import { Overlay } from '@angular/cdk/overlay';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { ToasterUtil } from '@app/utils/toaster.util';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  addToastEvent$: EventEmitter<Toast> = new EventEmitter<Toast>();
  toastSub:BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);
  toasts: Toast[] = [];
  toastNumber = 0;
    constructor(
    private injector: Injector,
    private liveAnnouncer: LiveAnnouncer,
    private breakpointObserver: BreakpointObserver,
  ) {}

  addToast(toast: Toast) {
    const overlay = this.injector.get(Overlay);
    let verticalOffset = 0;
    if (toast.noDuplicate) {
      const toastWithSameMessage = this.toasts.filter(iToast => {
        return iToast.text === toast.text && iToast.type === toast.type;
      });
      if (toastWithSameMessage.length) {
        // duplicate toast
        return;
      }
    }

    toast._id = this.toastNumber++;
    this.toasts.push(toast);
    ToasterUtil.toast$.subscribe(data => {
      this.toastClosed(data);
    });
    this.generateCSS(this.toasts.length);
    for (let toast of this.toasts) {
      const snackBar = new MatSnackBar(overlay, this.liveAnnouncer, this.injector, this.breakpointObserver, undefined, {});
      snackBar.openFromComponent(ToasterComponent, {
        data: toast,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: toast.duration,
        panelClass: [toast.type, `snackbar-margin-${verticalOffset}`],
      });
      verticalOffset += 1;
    }
    if (!isNaN(toast.duration) && toast.duration > -1) {
      setTimeout(() => {
        this.toastClosed(toast);

      }, toast.duration);
    }
  }

  generateCSS(maxSnackbars: number): void {
    const styleElement = document.createElement('style');
    let styles = '';
    for (let i = 0; i < maxSnackbars; i++) {
      styles += `.snackbar-margin-${i} { top: ${i * 50}px !important; }\n`;
    }
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
  }

  toastClosed(toast: Toast) {
    this.toasts = this.toasts.filter(iToast => iToast._id !== toast._id); 
    this.generateCSS(this.toasts.length);
  }
}
