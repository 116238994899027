import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ToasterUtil } from '@app/utils/toaster.util';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  constructor(
    public modal: MatSnackBarRef<ToasterComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  ngOnInit() {
  }

  onClose(){
    ToasterUtil.dispatchData(this.data);
    this.modal.dismiss();
  }
}
