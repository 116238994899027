import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { EnvService } from './core/services/env/env.service';
import { CustomToolbarService } from './shared/services/custom-toolbar.service';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { AppFacadeService } from './services/app-facade.service';
// POLYMER IMPORTS
// import 'nd-ui-components/src/nd-styles/external-stylesheets.js';
// import 'nd-ui-components/src/nd-badge/nd-badge.js';
// import 'nd-ui-components/src/nd-button/nd-button.js';
// import 'nd-ui-components/src/nd-button-toggle-group/nd-button-toggle-group.js';
// import 'nd-ui-components/src/nd-card/nd-card.js';
// import 'nd-ui-components/src/nd-checkbox/nd-checkbox.js';
// import 'nd-ui-components/src/nd-chip/nd-chip.js';
// import 'nd-ui-components/src/nd-collapse-button-group/nd-collapse-button-group.js';
// import 'nd-ui-components/src/nd-collapse-item/nd-collapse-item.js';
// import 'nd-ui-components/src/nd-combobox/nd-combobox.js';
// import 'nd-ui-components/src/nd-date-picker/nd-date-picker.js';
// import 'nd-ui-components/src/nd-dialog/nd-dialog.js';
// import 'nd-ui-components/src/nd-dialog-scrollable/nd-dialog-scrollable.js';
// import 'nd-ui-components/src/nd-drawer-panel/nd-drawer-panel.js';
// import 'nd-ui-components/src/nd-dropdown-menu/nd-dropdown-menu.js';
// import 'nd-ui-components/src/nd-fab/nd-fab.js';
// import 'nd-ui-components/src/nd-footer/nd-footer.js';
// import 'nd-ui-components/src/nd-icon/nd-icon.js';
// import 'nd-ui-components/src/nd-icon-button/nd-icon-button.js';
// import 'nd-ui-components/src/nd-icons/nd-icons.js';
// import 'nd-ui-components/src/nd-input/nd-input.js';
// import 'nd-ui-components/src/nd-item/nd-item.js';
// import 'nd-ui-components/src/nd-listbox/nd-listbox.js';
// import 'nd-ui-components/src/nd-menu-button/nd-menu-button.js';
// import 'nd-ui-components/src/nd-progress/nd-progress.js';
// import 'nd-ui-components/src/nd-publish-dialog/nd-publish-dialog.js';
// import 'nd-ui-components/src/nd-radio-button/nd-radio-button.js';
// import 'nd-ui-components/src/nd-radio-group/nd-radio-group.js';
// import 'nd-ui-components/src/nd-range-slider/nd-range-slider.js';
// import 'nd-ui-components/src/nd-share-dialog/nd-share-dialog.js';
// import 'nd-ui-components/src/nd-slider/nd-slider.js';
// import 'nd-ui-components/src/nd-spinner/nd-spinner.js';
// import 'nd-ui-components/src/nd-tabs/nd-tabs.js';
// import 'nd-ui-components/src/nd-toast/nd-toast.js';
// import 'nd-ui-components/src/nd-toggle-button/nd-toggle-button.js';
// import 'nd-ui-components/src/nd-toolbar/nd-toolbar.js';
// import 'nd-ui-components/src/nd-tooltip/nd-tooltip.js';
// import 'nd-ui-components/src/nd-chip/nd-chip-input.js';
// import 'nd-ui-components/src/nd-item/nd-icon-item.js';
// import 'nd-ui-components/src/nd-styles/default-theme.js';
// import 'nd-ui-components/src/lib/tooltip-service.js';
// import 'nd-ui-components/src/nd-styles/nd-card-global-styles.js';
import { MixpanelService } from '@app/core/services/mixpanel/mixpanel.service';
import { AuthenticationFacadeService } from './services/authentication-facade.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalService } from '@app/shared/services/modal/modal.service';
import { HeartBeatService } from '@app/core/services/heartbeat/heart-beat.service';
import { ForecastFacadeService } from '@app/main/forecasts/services/forecast-facade.service';
import { isNotEmpty } from '@app/utils';
import { PendoService } from '@app/core/services/pendo/pendo.service';
import { ForecastService } from '@app/main/forecasts/services';
import { AppUtilService } from '@app/services/app-util.service';
import {
  BRAND_BAR_SELECTOR,
  CAROUSEL_ITEM_SELECTOR,
  CONTAINER_FLUID_SELECTOR,
  MARKETING_PLAN_CONTAINER_SELECTOR,
  MARKETING_PLAN_DRAG_SCROLL_CONTENT_SELECTOR,
  MARKETING_PLAN_FLEX_NAME_SELECTOR,
  MP_FILTER_SELECTOR,
  TOOL_BAR_SELECTOR,
} from '@app/main/marketing-plans/components/marketing-plan/marketing-plan-common-variables';
import { EMPTY_STRING } from '@app/main/marketing-plans/components/concepts-and-skus/concept-and-skus-common-variables';
import { ApplicationInsightsService } from '@app/core/services/application-insights/application-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  /**
   * this will be useful to destroy all the subscriptions on component destroy
   * @type {Subject<any>}
   */
  private destroy$ = new Subject();
  customToolbarHide$: Subject<boolean>;

  loadingRouteConfig = false;
  isLoading$: Observable<number>;
  isLoading = 0;
  translations: any;
  // Polling the session for every 2 minutes
  HEART_BEAT_INTERVAL = 2 * 60 * 1000; // 2 mins
  isAuthenticated = false;
  apiPath: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private env: EnvService,
    private customToolbarService: CustomToolbarService,
    private router: Router,
    private facade: AppFacadeService,
    private forecastFacadeService: ForecastFacadeService,
    public authFacade: AuthenticationFacadeService,
    private mixpanelService: MixpanelService,
    private modalService: ModalService,
    private heartBeatService: HeartBeatService,
    private pendoService: PendoService,
    private forecastService: ForecastService,
    private appUtilService: AppUtilService,
    private applicationInsightsService: ApplicationInsightsService,
  ) {
    this.translate.setDefaultLang('en-US');
    this.customToolbarHide$ = this.customToolbarService.customToolbarHide$;
  }

  get studioApiUrl() {
    // TODO: this will be changed as per update from new ngx-component
    // returning null incase if the user is NOT authenticated because, we should not call studio APIs before the user gets authenticated.
    return this.isAuthenticated ? `${this.env.studioApiUrl}` : null;
  }

  ngOnInit() {
    // mixpanelService initialization when user is successfully authenticated
    // user token should be set.
    this.authFacade.getAuthState$
      .pipe(
        takeUntil(this.destroy$),
        filter(authState => authState.isAuthenticated),
      )
      .subscribe(authState => {
        this.isAuthenticated = authState.isAuthenticated;
        this.appUtilService.setUserData(authState.user);
        this.mixpanelService.init(authState.user.fullName);
        if (this.isAuthenticated) {
          this.forecastService.getStudioUserInfo().subscribe(resp => {
            const organizationId: string = resp.organizationId;
            this.pendoService.initPendo(authState.user, organizationId);
          });
        }
      });

    // Starting the heartbeat check
    setInterval(() => {
      this.heartBeatService.checkSessionStatus();
    }, this.HEART_BEAT_INTERVAL);

    // dispatching router config actions, these will be called when a module is lazily loaded
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.facade.showOnDemandSpinner();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.facade.hideOnDemandSpinner();
      }
    });
    this.translate
      .getTranslation('en-US')
      .pipe(takeUntil(this.destroy$))
      .subscribe(translations => {
        this.translations = translations;
      });

    this.forecastFacadeService.studioProjectId$
      .pipe(filter(studioProjectId => isNotEmpty(studioProjectId)))
      .subscribe(studioProjectId => {
        this.apiPath = `/projects/${studioProjectId}/projectNavigation`;
      });
  }

  navigationServiceURL() {
    return this.studioApiUrl + '/userNavigation';
  }

  logoutURL() {
    return this.env.authenticationProxyUrl + '/logout';
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const _hasClassName: boolean = this.hasClassName(
      event.target.className,
      BRAND_BAR_SELECTOR,
      TOOL_BAR_SELECTOR,
      MARKETING_PLAN_FLEX_NAME_SELECTOR,
      MARKETING_PLAN_DRAG_SCROLL_CONTENT_SELECTOR,
      MARKETING_PLAN_CONTAINER_SELECTOR,
      CAROUSEL_ITEM_SELECTOR,
      CONTAINER_FLUID_SELECTOR,
      MP_FILTER_SELECTOR,
    );
    if (_hasClassName) {
      this.appUtilService.resetMarketingPlanNameEditState();
    }
  }

  hasClassName(className, ...args): boolean {
    return args.some(a => className.includes(a)) || className === EMPTY_STRING;
  }

  // https://github.com/angular/angular/issues/6005#issuecomment-165905348
  ngAfterViewChecked() {
    this.facade.isLoading$.subscribe(data => {
      const isLoading = data;
      if (isLoading !== this.isLoading) {
        // check if it change, tell CD update view
        this.isLoading = isLoading;
        this.cdRef.detectChanges();
      }
    });
  }

  /**
   * ng destroy
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
