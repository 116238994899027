/* tslint:disable: no-duplicate-string */
/*tslint:disable: max-line-length*/
export const CONSUMER_PROMOTION_EVENTS = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
];

export const FREE_STANDING_INSERT = 'Free Standing Insert';

export const INSIDE_STORE_EVENTS = 'Inside Store Events';

export const OUTSIDE_STORE_EVENTS = 'Outside Store Events';

export const SAMPLE_EVENTS = [INSIDE_STORE_EVENTS, OUTSIDE_STORE_EVENTS];

export const COUPON_REQUIRED_FIELDS = [
  'type',
  'faceValue',
  'circulation',
  'dateDropped',
  'expirationPeriod',
  'promotionEventAssociationDetails',
  'otherProductDetails',
  'couponsOnPage',
  'eventByVehicle',
];

export const FREE_STANDING_INSERT_FIELDS = [...COUPON_REQUIRED_FIELDS, ...['adLocation', 'adSize']];

export const INSIDE_STORE_EVENTS_REQUIRED_FIELDS = [
  'type',
  'dateDropped',
  'samplesAvailableMM',
  'unDuplicatedHHAsOfTarget',
  'receiptAndUsage',
  'sampleAsOfFullSize',
  'promotionEventAssociationDetails',
];

export const OUTSIDE_STORE_EVENTS_REQUIRED_FIELDS = [
  'type',
  'dateDropped',
  'samplesAvailableMM',
  'receiptAndUsage',
  'sampleAsOfFullSize',
  'promotionEventAssociationDetails',
];

export const COUPON_KEY_VALUE_MAPPING = {
  Type: 'type',
  Description: 'description',
  Location: 'location',
  'Face Value': 'faceValue',
  'FaceValueRetailPrice': 'faceValueRetailPrice',
  'Circulation (MM)': 'circulation',
  'Date Dropped': 'dateDropped',
  'Expiration Period (weeks)': 'expirationPeriod',
  'Varieties/Sizes Valid on': 'varietiesSizesValidOn',
  'Associated with Another Promotion Event?': 'associatedWithAnotherPromotionEvent',
  'Redeemable on Other Products': 'redeemableOnOtherProducts',
  'National/Regional': 'nationalRegional',
  'Eligible purchase to redeem': 'eligiblePurchaseToRedeem',
  'Repeat Only': 'repeatOnly',
  '# of Coupons on page': 'couponsOnPage',
  'Redemption Rate': 'redemptionRate',
  'Event by Vehicle': 'eventByVehicle',
  'Ad Location': 'adLocation',
  'Ad Size': 'adSize',
  'Promotion event association details': 'promotionEventAssociationDetails',
  'Other Product details': 'otherProductDetails',
};

export const SAMPLE_KEY_VALUE_MAPPING = {
  Type: 'type',
  'Event Description': 'eventDescription',
  Location: 'location',
  'Date Dropped': 'dateDropped',
  '# of Samples Available (MM)': 'samplesAvailableMM',
  'Size of Sample(s)': 'sizeOfSample',
  'Receipt & Usage %': 'receiptAndUsage',
  '% ACV': 'acv',
  '# of Stores': 'noOfStores',
  'Distribution Outlet': 'distributionOutlet',
  'Unduplicated Households (MM)': 'unDuplicatedHouseholds',
  'UnDuplicated HH as % of Target': 'unDuplicatedHHAsOfTarget',
  'Varieties Sampled': 'varietiesSampled',
  'Associated with Another Promotion Event?': 'associatedWithAnotherPromotionEvent',
  'National/Regional': 'nationalRegional',
  'Sample Units': 'sampleUnits',
  'Sample As % of Full Size': 'sampleAsOfFullSize',
  'Event Cost in Millions': 'eventCostInMillions',
  'Promotion event association details': 'promotionEventAssociationDetails',
};

export const COUPON_EVENT_TABLE_HEADERS = [
  'Type',
  'Description',
  'Location',
  'Face Value',
  'Circulation (MM)',
  'Date Dropped',
  'Expiration Period (weeks)',
  'Event by Vehicle',
  'Eligible purchase to redeem',
  'Repeat Only',
  'Varieties/Sizes Valid on',
  'Associated with Another Promotion Event?',
  'Redeemable on Other Products',
  '# of Coupons on page',
  'National/Regional',
  'Redemption Rate',
];

export const IN_STORE_EVENT_TABLE_HEADERS = [
  'Unduplicated Households (MM)',
  'UnDuplicated HH as % of Target',
  '% ACV',
  'Distribution Outlet',
  '# of Stores',
];

export const OTHER_SAMPLE_HEADERS = [
  'Type',
  'Event Description',
  'Location',
  'Date Dropped',
  'Sample As % of Full Size',
  'Size of Sample(s)',
  'Sample Units',
  'Varieties Sampled',
  '# of Samples Available (MM)',
  'Receipt & Usage %',
  'Associated with Another Promotion Event?',
  'National/Regional',
  'Event Cost in Millions',
];

export const FREE_STANDING_INSERT_HEADERS = ['Ad Location', 'Ad Size'];

export const COUPONS = 'coupons';
export const COUPON = 'Coupon';
export const SAMPLES = 'samples';
export const SAMPLE = 'Sample';
export const NUMBER_OF_COUPONS_ON_PAGE = '# of Coupons on page';
export const EVENT_BY_VEHICLE = 'Event by Vehicle';
export const EVENT_COST_IN_MILLION = 'Event Cost in Millions';
export const REDEMPTION_RATE = 'Redemption Rate';
export const REDEMPTION_RATE_KEY = 'redemptionRate';
export const EVENT_COST_IN_MILLION_KEY = 'eventCostInMillions';
export const NULL_STRING = null;
export const TYPE_PARAM = '{{type}}';

export enum ValidationType {
  CURRENCY = 'currency',
  NUMERIC = 'numeric',
  PERCENTAGE = 'percentage',
  ALPHANUMERIC = 'alphanumeric',
  EXPIRATION_PERIOD = 'expirationPeriod',
  EVENT_BY_VEHICLE = 'eventByVehicle',
  FACE_VALUE = 'faceValue',
  CIRCULATION = 'circulation',
  COUPON_REDEMPTION_RATE = 'couponRedemptionRate',
  POSITIVE_INTEGER = 'positiveInteger',
  SAMPLE_AS_OF_FULL_SIZE = 'sampleAsOfFullSize',
}

export const REQUIRED_ERROR_MESSAGE_MAPPING = {
  type: 'app.consumer.promotion.type.required.validation.message',
  faceValue: 'app.consumer.promotion.face.value.required.validation.message',
  circulation: 'app.consumer.promotion.circulation.required.message',
  dateDropped: 'app.consumer.promotion.date.dropped.required.message',
  expirationPeriod: 'app.consumer.promotion.expiration.period.required.message',
  couponsOnPage: 'app.consumer.promotion.coupons.on.page.required.message',
  eventByVehicle: 'app.consumer.promotion.event.by.vehicle.required.message',
  samplesAvailableMM: 'app.consumer.promotion.number.of.samples.available.required.message',
  receiptAndUsage: 'app.consumer.promotion.receipt.and.usage.required.message',
  sampleAsOfFullSize: 'app.consumer.promotion.sample.as.percentage.of.full.size.required.message',
  associatedWithAnotherPromotionEvent:
    'app.consumer.promotion.sample.associated.with.another.promotion.event.required.message',
  promotionEventAssociationDetails:
    'app.consumer.promotion.event.association.details.required.message',
  redeemableOnOtherProducts:
    'app.consumer.promotion.redeemable.on.other.product.event.required.message',
  otherProductDetails: 'app.consumer.other.product.details.required.message',
  unDuplicatedHHAsOfTarget: 'app.consumer.promotion.unduplicated.hh.of.target.required.message',
  redemptionRate: 'app.consumer.promotion.redemption.rate.required.error.message',
  sizeOfSample: 'app.consumer.promotion.size.of.samples.required.message',
  adLocation: 'app.consumer.promotion.ad.location.required.message',
  adSize: 'app.consumer.promotion.ad.size.required.message',
};

export const WARNING_MESSAGE_MAPPING = {
  dateDropped: 'app.consumer.promotion.date.dropped.warning.message',
};

export const ERROR_MESSAGE_MAPPING = {
  faceValue: 'app.consumer.promotion.face.value.validation.message',
  dateDropped: 'app.consumer.promotion.date.dropped.validation.message',
  expirationPeriod: 'app.consumer.promotion.expiration.period.validation.message',
  eventByVehicle: 'app.consumer.promotion.event.by.vehicle.validation.message',
  unDuplicatedHouseholds: 'app.consumer.promotion.unduplicated.households.validation.message',
  associatedWithAnotherPromotionEvent:
    'app.consumer.promotion.sample.associated.with.another.promotion.event.validation.message',
  promotionEventAssociationDetails:
    'app.consumer.promotion.event.association.details.validation.message',
  redeemableOnOtherProducts:
    'app.consumer.promotion.redeemable.on.other.product.event.validation.message',
  otherProductDetails: 'app.consumer.other.product.details.validation.message',
  samplesAvailableMM:
    'app.consumer.promotion.number.of.samples.available.numeric.validation.error.message',
  receiptAndUsage: 'app.consumer.promotion.receipt.and.usage.numeric.validation.error.message',
  redemptionRate: 'app.consumer.promotion.redemption.rate.numeric.validation.error.message',
  acv: 'app.consumer.promotion.acv.numeric.validation.error.message',
  sampleAsOfFullSize:
    'app.consumer.promotion.sample.as.percentage.of.full.size.numeric.percentage.validation.error.message',
  circulation: 'app.consumer.promotion.circulation.numeric.validation.error.message',
  eventDescription: 'app.consumer.promotion.eventDescription.validation.message',
  description: 'app.consumer.promotion.description.validation.message',
  location: 'app.consumer.promotion.location.validation.message',
  eventCostInMillions: 'app.consumer.promotion.event.cost.in.million.validation.message',
  couponsOnPage: 'app.consumer.promotion.coupons.on.page.validation.message',
  unDuplicatedHHAsOfTarget: 'app.consumer.promotion.unduplicated.hh.of.target.validation.message',
  noOfStores: 'app.consumer.promotion.no.of.stores.numeric.validation.error.message',
  distributionOutlet: 'app.consumer.promotion.distribution.outlet.validation.message',
  sizeOfSample: 'app.consumer.promotion.size.of.sample.validation.message',
};

export const VALIDATION_MESSAGE_MAPPING = {
  eventCostInMillions:
    'app.consumer.promotion.event.cost.in.million.not.a.number.validation.message',
};

export const DATE_DROPPED_HEADER_TEXT = 'Date Dropped';
export const DATE_DROPPED_KEY = 'dateDropped';
export const ASSOCIATED_WITH_ANOTHER_PROMOTION_EVENT_TEXT =
  'Associated with Another Promotion Event?';
export const ASSOCIATED_WITH_ANOTHER_PROMOTION_EVENT_TEXT_KEY =
  'associatedWithAnotherPromotionEvent';
export const REDEEMABLE_ON_OTHER_PRODUCTS = 'Redeemable on Other Products';
export const REDEEMABLE_ON_OTHER_PRODUCTS_KEY = 'redeemableOnOtherProducts';
export const PROMOTION_EVENT_ASSOCIATION_DETAILS = 'Promotion event association details';
export const PROMOTION_EVENT_ASSOCIATION_DETAILS_KEY = 'promotionEventAssociationDetails';
export const OTHER_PRODUCT_DETAILS = 'Other Product details';
export const OTHER_PRODUCT_DETAILS_KEY = 'otherProductDetails';
export const AD_LOCATION = 'Ad Location';
export const AD_SIZE = 'Ad Size';
export const VARIETIES_SIZES_VALID_ON = 'Varieties/Sizes Valid on';
export const ELIGIBLE_PURCHASE_TO_REDEEM = 'Eligible purchase to redeem';
export const SAMPLE_UNITS = 'Sample Units';
export const VARIETIES_SAMPLED = 'Varieties Sampled';
export const STRING_YES = 'Yes';
export const STRING_NO = 'No';
export const ALL_TEXT = 'All';
export const NATIONAL_REGIONAL = 'National/Regional';
export const REPEAT_ONLY = 'Repeat Only';
export const FACE_VALUE = 'Face Value';
export const TYPE = 'Type';
export const TYPE_KEY = 'type';
export const AD_LOCATION_KEY = 'adLocation';
export const AD_SIZE_KEY = 'adSize';
export const DROPDOWN_DEFAULT = 'app.consumer.promotion.default.dropdown.text';
export const REQUIRED_DROPDOWN_DEFAULT = 'app.consumer.promotion.required.default.dropdown.text';
export const CLIENT_REDEMPTION_RATES =
  'app.consumer.promotion.country.dropdown.client.redemption.rates';
export const EMPTY_TEXT_DEFAULT = 'app.forecast.empty';
export const DROPDOWN_OPTION_NO = 'app.consumer.promotion.no.label';
export const DROPDOWN_OPTION_YES = 'app.consumer.promotion.yes.label';
export const SKUS_DROPDOWN_DEFAULT_OPTION =
  'app.consumer.promotion.varieties.default.dropdown.text';
export const DESCRIPTION_TABLE_HEADER = 'Description';
export const EVENT_DESCRIPTION_TABLE_HEADER = 'Event Description';
export const IS_DESCRIPTION_AUTO_GENERATED = 'isDescriptionAutoGenerated';

export const ALPHANUMERIC_REGEX = /^[\w\s\-\"\,\'\&\%\*\+\(\)\$]{1,200}$/;
export const ALPHANUMERIC_WITH_OUT_CHAR_LIMIT = /^[\w\s\-\"\,\'\&\%\*\+\(\)\$]+$/;
export const ALPHANUMERIC_CHARACTER_LIMIT_REGEX = /^[\w\s\-\"\,\'\&\%\*\+\(\)\$]{1,200}$/;
export const CURRENCY_REGEX = /^(?!0+(?:\.0+)?$)\d?\d(?:\.\d\d?)?$/;
export const NUMERIC_REGEX = /^(0|[0-9]\d*\.)?\d+$/;
export const COUPON_REDEMPTION_RATE_REGEX = /^(?:\d{1,2}(?:\.\d{1,2})?|99(?:\.00?)?)$/;
export const PERCENTAGE_REGEX = /^(?:\d{1,2}(?:\.\d{1,10})?|100(?:\.0?0)?)$/;
export const SAMPLE_AS_OF_FULL_SIZE_REGEX = /^(?:[1-9]\d?(?:\.\d{1,10})?|100(?:\.0?0)?)$/;
export const EXPIRATION_PERIOD_REGEX = /^([1-9]|[1-4][0-9]|5[012])$/;
export const EVENT_BY_VEHICLE_REGEX = /^([1-9]|[1][0-9]|2[0])$/;
export const EMPTY_STRING_REGEX = /^$|^.*@.*\..*$/;
export const FACE_VALUE_REGEX = /^(?=.*[1-9])\d{0,2}(?:\.\d{0,2})?$/;
export const CIRCULATION_REGEX = /^(?=.*[1-9])\d{0,3}(?:\.\d{0,6})?$/;
export const POSITIVE_INTEGER_REGEX = /^\d*[1-9]\d*$/;

export const NON_CONTENT_EDITABLE_TABLE_HEADERS = [
  DATE_DROPPED_HEADER_TEXT,
  ASSOCIATED_WITH_ANOTHER_PROMOTION_EVENT_TEXT,
  AD_LOCATION,
  AD_SIZE,
  VARIETIES_SIZES_VALID_ON,
  ELIGIBLE_PURCHASE_TO_REDEEM,
  SAMPLE_UNITS,
  VARIETIES_SAMPLED,
  REPEAT_ONLY,
  NATIONAL_REGIONAL,
  REDEEMABLE_ON_OTHER_PRODUCTS,
  FACE_VALUE,
  TYPE,
];

export const DROPDOWN_HEADERS = [
  ASSOCIATED_WITH_ANOTHER_PROMOTION_EVENT_TEXT,
  AD_LOCATION,
  AD_SIZE,
  ELIGIBLE_PURCHASE_TO_REDEEM,
  SAMPLE_UNITS,
  REPEAT_ONLY,
  NATIONAL_REGIONAL,
  REDEEMABLE_ON_OTHER_PRODUCTS,
];

export enum EventPosition {
  START = 'Start',
  END = 'End',
}
