import { EventEmitter, Injectable } from '@angular/core';
import {
  RelativePriceAdjustment,
  RelativePriceAdjustmentUI,
  RelativePriceAdjustmentUIData,
  RPAUISkuData,
} from '@app/main/forecast-runs/models/relative-price-adjustment';
import { ForecastRunsFacadeService } from '@app/main/forecast-runs/services/forecast-runs-facade.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import * as rpaCommonVariables from '@app/main/forecast-runs/utils/common-variables';
import { Estimate } from '@app/main/forecast-runs/models/estimate';
import {
  cloneJson,
  compareJson,
  ESTIMATE_SOURCE_CHANGED,
  isEmpty,
  isNotEmpty,
  validateAlphaNumeric,
  validateEmpty,
} from '@app/utils';
import * as rpaActions from '@app/main/forecast-runs/_store/actions/relative-price-adjustment.actions';
import { DropDownItem } from '@app/shared/components/multi-select-dropdown/multi-select-dropdown.model';
import { Concept } from '@app/main/forecast-runs/models/concepts-and-skus';
import * as conceptAndSkusActions from '@app/main/forecast-runs/_store/actions/concepts-and-skus.actions';
import { MarketingPlanEventTrackingService } from '@app/services/marketing-plan-event-tracking.service';
import * as adjustmentActions from '@app/main/forecast-runs/_store/actions/adjustment.actions';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef } from '@app/shared/components/modal/modal-ref';
import { rpaTranslationKeys } from '@app/main/forecast-runs/translation-keys';
import * as mixpanelActions from '@app/main/forecasts/_store/actions/mixpanel.actions';
import { getDuplicateName } from '@app/utils/common-functions';
import { ADJUSTMENT_TYPE, RPA_SCENARIONAME } from '@app/main/forecast-runs/utils/common-variables';
import {
  BlendedRPAUI,
  BlendedRPAUIData,
} from '@app/main/forecast-runs/models/blended-relative-price-adjustment';
import { RelativePriceAdjustmentsCommonService } from './relative-price-adjustments-common.service';
import { ForecastFacadeService } from '@app/main/forecasts/services/forecast-facade.service';

@Injectable({
  providedIn: 'root',
})
export class RelativePriceAdjustmentsUiService {
  forecastRunId: string;
  forecastRun: string;
  estimates: Estimate[];
  relativePriceAdjustments = [];
  relativePriceAdjustmentsUIData = [];
  relativePriceAdjustmentUIBackupData = [];
  skus: RPAUISkuData[] = [];
  translations: any;
  nonFormErrorMessages: string[] = [];
  dropDownItems: DropDownItem[] = [];
  validateErrorEvent = new EventEmitter<boolean>(); // this event will trigger  RPA error validation
  // will trigger this event to update the horizontal scroll in case of duplicate scenario
  updateScrollForDuplicateEvent = new EventEmitter<any>();
  isDuplicateRPAScenario: string; // used to identify Duplicate operation
  failedRequestData: any; // this will be send to recalculate data if any of the RPA calculation fails.
  commonAppliesToEstimateData = []; // this will hold data of available estimates
  appliesToEstimateDataChangeEvent = new EventEmitter<any>();
  forecastId: string;
  lockedEstimateIds: string[] = [];
  isDeliverableUnlocked: boolean;

  constructor(
    public forecastRunsFacade: ForecastRunsFacadeService,
    private translate: TranslateService,
    private modal: ModalRef,
    public relativePriceAdjustmentsCommonService: RelativePriceAdjustmentsCommonService,
    public forecastFacadeService: ForecastFacadeService,
  ) {
    // fetch translations
    this.translate.setDefaultLang('en-US');
    this.resolveAllTranslations(rpaTranslationKeys).subscribe(translations => {
      this.translations = translations;
    });
    // fetch forecast Id
    this.forecastRunsFacade.currentRouteForecastId$.subscribe(forecastId => {
      this.forecastId = forecastId;
    });
    // fetch forecast run Id
    this.forecastRunsFacade.forecastRuns$.subscribe(forecastRuns => {
      if (forecastRuns[0]) {
        this.forecastRunId = forecastRuns[0].id;
      }
    });
    // fetch estimates
    this.forecastRunsFacade.estimates$
      .pipe(
        filter(estimates => !!estimates),
        map(estimates =>
          estimates.filter(
            estimate => estimate.type !== rpaCommonVariables.ESTIMATE_TYPE.BENCHMARK,
          ),
        ),
      )
      .subscribe(estimates => {
        this.estimates = estimates;
        this.generateDropDownItems();
      });
    // fetch RPA data
    this.forecastRunsFacade.relativePriceAdj$.pipe(filter(rpa => !!rpa)).subscribe(rpa => {
      this.relativePriceAdjustments = cloneJson(rpa);
      this.fetchRPAData();
    });
    if (!this.relativePriceAdjustments) {
      this.forecastRunsFacade.dispatch(
        rpaActions.fetchRelativePriceAdjustments({ forecastRunId: this.forecastRunId }),
      );
    }

    this.forecastFacadeService.forecast$
          .subscribe(forecast => {
            this.isDeliverableUnlocked = forecast?.data?.isDeliverableUnlocked;
            if (forecast?.data?.lockedComponentIds?.estimates) {
              this.lockedEstimateIds = forecast?.data?.lockedComponentIds?.estimates;
            }
            else {
              this.lockedEstimateIds = [];
            }
          });
  }

  resolveAllTranslations(translations: string[]) {
    return this.translate.get(translations);
  }

  fetchRPAData() {
    if (isEmpty(this.relativePriceAdjustments)) {
      this.relativePriceAdjustmentsUIData = [];
      this.relativePriceAdjustmentUIBackupData = [];
      this.addRPAScenario();
    } else {
      this.constructRPAData();
    }
    this.findAppliesToEstimatesAvailableData();
    // fetchRpaData is called when new RPA data is added, hence change the overlay height
    setTimeout(() => {
      this.relativePriceAdjustmentsCommonService.setRowNumbersEvent.emit(true);
    });
  }

  /*
   Creates common Applies to estimates dropdown data with details like
   to which RPA Scenario it is applied from estimates data
    We will use this common data to construct applies to dropdown data for each RPA scenario
   */
  findAppliesToEstimatesAvailableData() {
    this.commonAppliesToEstimateData = this.estimates.map(estimate => {
      return {
        id: estimate.id,
        name: estimate.name,
        marketingPlanId: estimate.marketingPlanId,
        alreadyAppliedAdjustmentName: this.isEstimateAlreadyApplied(estimate.id),
        alreadyApplied: isNotEmpty(this.isEstimateAlreadyApplied(estimate.id)),
      };
    });
    this.appliesToEstimateDataChangeEvent.next(true);
  }

  isEstimateAlreadyApplied(estimateId) {
    let alreadyAppliedAdjustmentName = null;
    this.relativePriceAdjustmentsUIData.forEach(rpaUIData => {
      if (
        rpaUIData.rpaData.appliesToEstimates &&
        rpaUIData.rpaData.appliesToEstimates.includes(estimateId)
      ) {
        alreadyAppliedAdjustmentName = rpaUIData.rpaData.description;
      }
    });
    return alreadyAppliedAdjustmentName;
  }

  constructRPAData() {
    const rpaUIDataObject = cloneJson(this.relativePriceAdjustmentsUIData),
      rpaUIBackupDataObject = cloneJson(this.relativePriceAdjustmentUIBackupData);
    this.relativePriceAdjustmentsUIData = [];
    this.relativePriceAdjustmentUIBackupData = [];
    this.relativePriceAdjustments.forEach((rpaData, index) => {
      let rpaDataObj, rpaBackupObj;
      const currentRPAUIData = this.isRPAPresentInArray(rpaData, rpaUIDataObject);
      if (
        isNotEmpty(rpaUIDataObject) &&
        isNotEmpty(currentRPAUIData) &&
        currentRPAUIData.persistData
      ) {
        currentRPAUIData.persistData = false;
        rpaDataObj = cloneJson(currentRPAUIData);
        rpaBackupObj = this.isRPAPresentInArray(rpaData, rpaUIBackupDataObject);
        if (rpaData.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA) {
          this.updateBlendedRpaWeightsDataAfterSave(rpaDataObj, rpaBackupObj);
        }
      } else {
        rpaDataObj =
          rpaData.adjustmentType === ADJUSTMENT_TYPE.RPA
            ? this.constructRPAUIData(rpaData)
            : this.constructBlendedRPA(rpaData);
        rpaBackupObj = cloneJson(rpaDataObj);
      }
      this.relativePriceAdjustmentsUIData.push(rpaDataObj);
      this.relativePriceAdjustmentUIBackupData.push(rpaBackupObj);
    });
    // insert any unsaved RPAs
    const unsavedRPACount = this.pushNewlyAddedRPAScenarios(rpaUIDataObject, rpaUIBackupDataObject);
    // generate unique sku names
    this.generateUniqueSkuNames();
    if (isNotEmpty(this.isDuplicateRPAScenario)) {
      let rpaCountAfterCurrentOne = 0;
      if (this.isDuplicateRPAScenario === ADJUSTMENT_TYPE.RPA) {
        rpaCountAfterCurrentOne =
          unsavedRPACount +
          this.relativePriceAdjustments.filter(
            rpa => rpa.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA,
          ).length +
          1;
      }
      this.updateScrollForDuplicateEvent.next({ unsavedRPACount: rpaCountAfterCurrentOne });
      this.isDuplicateRPAScenario = null;
    }
  }

  /*
    push any unsaved changes after any Duplicate, delete, revert operation
   */
  pushNewlyAddedRPAScenarios(rpaUIDataObject, rpaUIBackupDataObject) {
    let unsavedRPACount = 0;
    rpaUIDataObject.forEach(rpaUIData => {
      /*
        If RPA is not present in response and don't have ID means,
        its a new created RPA scenario and we need to show the unsaved RPA in modal
       */
      const newRpaUIData = this.isRPAPresentInArray(
          rpaUIData.rpaData,
          this.relativePriceAdjustmentsUIData,
        ),
        newRpaBackupUIData = this.isRPAPresentInArray(rpaUIData.rpaData, rpaUIBackupDataObject);
      if (isEmpty(newRpaUIData) && isEmpty(rpaUIData.rpaData.id)) {
        rpaUIData.persistData = false;
        if (rpaUIData.rpaData.adjustmentType === ADJUSTMENT_TYPE.RPA) {
          unsavedRPACount++;
          // Insert unsaved RPA scenarios before Blended RPAs
          const rpaCount = this.findRPACount();
          this.relativePriceAdjustmentsUIData.splice(rpaCount, 0, rpaUIData);
          this.relativePriceAdjustmentUIBackupData.splice(rpaCount, 0, newRpaBackupUIData);
        } else {
          this.updateBlendedRpaWeightsDataAfterSave(rpaUIData, newRpaBackupUIData);
          this.relativePriceAdjustmentsUIData.push(rpaUIData);
          this.relativePriceAdjustmentUIBackupData.push(newRpaBackupUIData);
        }
      }
    });
    return unsavedRPACount;
  }

  isRPAPresentInArray(rpaData, rpaArray) {
    let rpaUIData = null;
    rpaArray.forEach(rpaItem => {
      if (rpaItem.rpaData.generatedScenarioName === rpaData.generatedScenarioName) {
        rpaUIData = cloneJson(rpaItem);
      }
    });
    return rpaUIData;
  }

  constructBlendedRPA(blendedRPA) {
    const blendedRPAUiData = new BlendedRPAUIData();
    blendedRPAUiData.rpaData = cloneJson(blendedRPA);
    blendedRPAUiData.rpaData.rpaWeights = [];
    blendedRPAUiData.rpaData.rpaWeights = this.getSavedRelativePriceAdjustments().map(rpaData => {
      return {
        relativePriceAdjustmentId: rpaData.id,
        relativePriceAdjustmentName: rpaData.description,
        weight: this.getBlendedRpaWeight(blendedRPA.rpaWeights, rpaData.id),
        selected: this.isRPASelectedInBlendedScenario(blendedRPA.rpaWeights, rpaData.id),
        rpaValue: rpaData.finalRelativePrice,
        isRPAValueOverriden: this.isFinalRPAValueOverriden(
          rpaData.finalRelativePrice,
          rpaData.boundedRelativePrice,
        ),
        isWeightInvalid: false,
      };
    });
    /*
    calculating Final RPA value in construction method, because if any of the
    RPAs final value is changed then blended final RPA value will be updated automatically in server side.
    if we fetch the latest data to know the latest value existing unsaved changes will be gone
    to avoid  that calculating the final RPA value in construct method
     */
    this.calculateFinalRPAValue(blendedRPAUiData);
    return blendedRPAUiData;
  }

  /**
   * Write code here to update any of the existing RPA data
   * @param rpaIndex
   */
  updateBlendedRpaWeightsDataAfterSave(rpaUIData, rpaBackupData) {
    const existingRpas = this.getSavedRelativePriceAdjustments();
    // if duplicate operation then we have to insert it
    if (
      isNotEmpty(this.isDuplicateRPAScenario) &&
      this.isDuplicateRPAScenario === ADJUSTMENT_TYPE.RPA
    ) {
      const duplicatedRPA = existingRpas[existingRpas.length - 1];
      const newRpaWeight = {
        relativePriceAdjustmentId: duplicatedRPA.id,
        relativePriceAdjustmentName: duplicatedRPA.description,
        weight: 0,
        selected: false,
        rpaValue: duplicatedRPA.finalRelativePrice,
        isRPAValueOverriden: this.isFinalRPAValueOverriden(
          duplicatedRPA.finalRelativePrice,
          duplicatedRPA.boundedRelativePrice,
        ),
        isWeightInvalid: false,
      };
      rpaUIData.rpaData.rpaWeights.push(cloneJson(newRpaWeight));
      rpaBackupData.rpaData.rpaWeights.push(cloneJson(newRpaWeight));
    } else {
      const rpaWeights = rpaUIData.rpaData.rpaWeights || [];
      rpaWeights.forEach((rpaWeight, index) => {
        const rpaPresent = existingRpas.find(rpa => rpa.id === rpaWeight.relativePriceAdjustmentId);
        if (rpaPresent) {
          // revert operation, update final rpa value
          rpaUIData.rpaData.rpaWeights[index].rpaValue = rpaPresent.value;
          rpaUIData.rpaData.rpaWeights[index].isRPAValueOverriden = this.isFinalRPAValueOverriden(
            rpaPresent.finalRelativePrice,
            rpaPresent.boundedRelativePrice,
          );
          rpaBackupData.rpaData.rpaWeights[index].rpaValue = rpaPresent.value;
          rpaBackupData.rpaData.rpaWeights[
            index
          ].isRPAValueOverriden = this.isFinalRPAValueOverriden(
            rpaPresent.finalRelativePrice,
            rpaPresent.boundedRelativePrice,
          );
        } else {
          // RPA is deleted & remove this RPA weight from both data and backup
          rpaUIData.rpaData.rpaWeights.splice(index, 1);
          rpaBackupData.rpaData.rpaWeights.splice(index, 1);
        }
      });
    }
  }

  getSavedRelativePriceAdjustments() {
    return this.relativePriceAdjustments.filter(rpa => rpa.adjustmentType === ADJUSTMENT_TYPE.RPA);
  }

  isRPASelectedInBlendedScenario(blendedWeights, rpaId) {
    const rpaBlendedWeights = blendedWeights.filter(
      weight => weight.relativePriceAdjustmentId === rpaId,
    );
    return rpaBlendedWeights.length > 0;
  }

  getBlendedRpaWeight(blendedWeights, rpaId) {
    const weights = blendedWeights.filter(weight => weight.relativePriceAdjustmentId === rpaId);
    return weights.length > 0 ? weights[0].weight * 100 : null;
  }

  constructRPAUIData(rpaData) {
    const rpaUiData = new RelativePriceAdjustmentUIData();
    rpaUiData.rpaData = cloneJson(rpaData);
    if (rpaData.skus) {
      rpaUiData.rpaData.skus = [];
      rpaData.skus.forEach(skuItem => {
        const skuData = new RPAUISkuData();
        skuData.skuId = skuItem.skuId;
        skuData.name = skuItem.name;
        skuData.absoluteRetailPrice = skuItem.absoluteRetailPrice;
        skuData.unitSize = skuItem.unitSize;
        skuData.pricePerVolume = skuItem.pricePerVolume;
        skuData.varietySplit = skuItem.varietySplit;
        skuData.unitMeasure = skuItem.unitMeasure;
        rpaUiData.rpaData.skus.push(skuData);
      });
    }
    // verify overriden data
    rpaUiData.isTotalPricePerVolumeOverriden = this.isTotalPricePerVolumeValueOverriden(
      rpaUiData.rpaData.totalPricePerVolume,
      rpaUiData,
    );
    rpaUiData.isCatPricePerVolumeOverriden = this.isCatPricePerVolumeValueOverriden(
      rpaUiData.rpaData.pricePerVolume,
      rpaUiData.rpaData.retailSales,
      rpaUiData.rpaData.totalVolumeSales,
    );
    rpaUiData.isBelievedPriceElasticityOverriden = this.isBelievedPriceElasticityValueOverriden(
      rpaUiData.rpaData.believedPriceElasticity,
    );
    rpaUiData.isFinalRPAOverriden = this.isFinalRPAValueOverriden(
      rpaUiData.rpaData.finalRelativePrice,
      rpaUiData.rpaData.boundedRelativePrice,
    );
    return rpaUiData;
  }

  generateDropDownItems() {
    if (this.estimates.length > 0) {
      this.dropDownItems = this.estimates.map((estimate, index) => {
        return {
          label: estimate.name,
          value: estimate.id,
          marketingPlanId: estimate.marketingPlanId,
          selected: false,
        };
      });
    }
    if (this.estimates[0]) {
      this.forecastRun = this.estimates[0].forecastRun.id;
    }
  }

  /*
   Generates names
   */
  generateRPAScenarioName() {
    if (this.relativePriceAdjustmentsUIData && this.relativePriceAdjustmentsUIData.length > 0) {
      const rpaNumbers = this.relativePriceAdjustmentsUIData.map(data => {
        const rpaNameSplitted = data.rpaData.generatedScenarioName.split(' ');
        let rpaNumber = 0;
        if (
          rpaNameSplitted.length === 3 &&
          rpaNameSplitted[0].toLowerCase() === 'RPA'.toLowerCase() &&
          rpaNameSplitted[1].toLowerCase() === 'Scenario'.toLowerCase()
        ) {
          rpaNumber = isNaN(+rpaNameSplitted[2]) ? 0 : +rpaNameSplitted[2];
        }
        return rpaNumber;
      });
      const lastTrialAndRepeatNumber = this.relativePriceAdjustmentsUIData.length
        ? Math.max(...rpaNumbers)
        : 0;
      return `${rpaCommonVariables.RPA_SCENARIO_NAME_PREFIX} ${lastTrialAndRepeatNumber + 1}`;
    } else {
      return `${rpaCommonVariables.RPA_SCENARIO_NAME_PREFIX} 1`;
    }
  }

  estimateSelected(estimate, rpaIndex = 0) {
    // update sku source
    this.relativePriceAdjustmentsUIData[rpaIndex].rpaData.skuSource = estimate
      ? estimate.value
      : null;
    const rpaUIData = cloneJson(this.relativePriceAdjustmentsUIData[rpaIndex]);
    // update skus data
    const conceptsAndSkusSubscription = this.forecastRunsFacade.conceptsAndSkus$
      .pipe(filter(estimates => !!estimates))
      .subscribe((concepts: Concept[]) => {
        let estimateConcepts;
        if (isNotEmpty(concepts)) {
          estimateConcepts = concepts.filter(
            concept => concept.marketingPlanId === estimate.marketingPlanId,
          );
        }
        if (isNotEmpty(estimateConcepts)) {
          const conceptSkus = estimateConcepts[0].skus;
          rpaUIData.rpaData.skus = [];
          if (isNotEmpty(conceptSkus)) {
            conceptSkus.forEach(conceptSku => {
              rpaUIData.rpaData.skus.push(this.updateSkusData(conceptSku));
            });
          }
          this.relativePriceAdjustmentUIBackupData[rpaIndex].rpaData.skus = cloneJson(
            rpaUIData.rpaData.skus,
          );
          this.resetSkusData(rpaUIData);
          this.resetSkusData(this.relativePriceAdjustmentUIBackupData[rpaIndex]);
          this.relativePriceAdjustmentsUIData[rpaIndex] = rpaUIData;
          // generate unique skus
          this.generateUniqueSkuNames();
          this.updateCalculateButtonFlag(rpaIndex);
          conceptsAndSkusSubscription.unsubscribe();
        } else {
          this.forecastRunsFacade.dispatch(
            conceptAndSkusActions.fetchConceptAndSkus({
              marketingPlanId: estimate ? estimate.marketingPlanId : '',
            }),
          );
        }
      });
    // mix panel save event
    this.forecastRunsFacade.dispatch(
      mixpanelActions.trackMixpanelEvent({
        id: ESTIMATE_SOURCE_CHANGED,
        action: {
          changedEstimateSource: estimate.label,
        },
      }),
    );
  }

  resetSkusData(rpaData) {
    // clear total values
    rpaData.totalVarietySplit = null;
    rpaData.totalAbsoluteRetailPrice = null;
    rpaData.totalPricePerVolume = null;
    rpaData.rpaData.totalPricePerVolume = null;
    // clear invalid fields
    rpaData.isTotalVarietySplitInvalid = false;
    rpaData.isTotalPricePerVolumeInvalid = false;
    // update overriden flags
    rpaData.isTotalPricePerVolumeOverriden = false;
  }

  generateUniqueSkuNames() {
    this.skus = [];
    this.relativePriceAdjustmentsUIData.forEach(rpaUIData => {
      if (rpaUIData.rpaData.adjustmentType === ADJUSTMENT_TYPE.RPA) {
        rpaUIData.rpaData.skus.forEach(sku => {
          if (!this.isSkuAlreadyExists(sku)) {
            const skuData = new RPAUISkuData();
            skuData.skuId = sku.skuId;
            skuData.name = sku.name;
            this.skus.push(skuData);
          }
        });
      }
    });
  }

  isSkuAlreadyExists(sku) {
    let exists = false;
    this.skus.forEach(skuElement => {
      if (skuElement.name === sku.name) {
        exists = true;
      }
    });
    return exists;
  }

  /*
   A method which converts cell's text content to it's appropriate source data type
  */
  getFormattedValue(value, decimalPlaces = 2) {
    if (isEmpty(value)) {
      return null;
    } else if (!isNaN(value)) {
      return Number((+value).toFixed(decimalPlaces));
    } else {
      return value;
    }
  }

  /*
     Focus out handler of cell- which is responsible for setting the any formats/validates that have been
     defined as part of the cell config
    */
  focusOutHandler(event, rpaIndex = 0, index = 0, param = null) {
    const newUnMaskedValue = event.target.textContent.trim();
    const key = event.target.getAttribute('data-name'),
      oldUnMaskedValue = event.target.getAttribute('data-unmasked-value');
    let decimalPlaces = 2;
    if (key && key.toString().includes('ctrilaf')) {
      decimalPlaces = 1;
    }
    const newFormattedValue = this.getFormattedValue(newUnMaskedValue, decimalPlaces);
    if (
      (this.isValueChanged(oldUnMaskedValue, newFormattedValue) &&
        !this.compareRPANumbers(oldUnMaskedValue, newFormattedValue)) ||
      !oldUnMaskedValue
    ) {
      this.updateRPAData(event, key, rpaIndex, index, param, newFormattedValue);
    }
  }

  updateRPAData(event, key, rpaIndex, skuIndex, param, newFormattedValue) {
    let skuDataChanged = false,
      calculateCatPricePerVolume = false;
    event.target.textContent = newFormattedValue;
    const rpaUIData = this.relativePriceAdjustmentsUIData[rpaIndex];
    // do data validations
    switch (key) {
      case rpaCommonVariables.RPA_CTRILAF_LABEL:
        rpaUIData.rpaData.skus[skuIndex].varietySplit = newFormattedValue;
        rpaUIData.rpaData.skus[skuIndex].isVarietySplitInvalid = this.isInvalid(
          newFormattedValue,
          false,
          true,
          rpaCommonVariables.RPA_CTRILAF_MIN_VALUE,
          rpaCommonVariables.RPA_CTRILAF_MAX_VALUE,
        );
        skuDataChanged = true;
        break;
      case rpaCommonVariables.RPA_ABSOLUTE_RETAIL_PRICE_LABEL:
        rpaUIData.rpaData.skus[skuIndex].absoluteRetailPrice = newFormattedValue;
        rpaUIData.rpaData.skus[skuIndex].isAbsoluteRetailPriceInvalid = this.isInvalid(
          newFormattedValue,
          false,
          false,
        );
        if (!rpaUIData.rpaData.skus[skuIndex].isAbsoluteRetailPriceInvalid) {
          rpaUIData.rpaData.skus[skuIndex].pricePerVolume = this.calculateSkuPricePerVolume(
            rpaUIData.rpaData.skus[skuIndex].absoluteRetailPrice,
            rpaUIData.rpaData.skus[skuIndex].unitSize,
          );
        }
        skuDataChanged = true;
        break;
      case rpaCommonVariables.RPA_UNIT_SIZE_LABEL:
        rpaUIData.rpaData.skus[skuIndex].unitSize = newFormattedValue;
        rpaUIData.rpaData.skus[skuIndex].isUnitSizeInvalid = this.isInvalid(
          newFormattedValue,
          false,
          false,
        );
        if (!rpaUIData.rpaData.skus[skuIndex].isUnitSizeInvalid) {
          rpaUIData.rpaData.skus[skuIndex].pricePerVolume = this.calculateSkuPricePerVolume(
            rpaUIData.rpaData.skus[skuIndex].absoluteRetailPrice,
            rpaUIData.rpaData.skus[skuIndex].unitSize,
          );
        }
        skuDataChanged = true;
        break;
      case rpaCommonVariables.RPA_TOTAL_PRICE_PER_VOLUME:
        rpaUIData.totalPricePerVolume = newFormattedValue;
        rpaUIData.isTotalPricePerVolumeInvalid = this.isInvalid(newFormattedValue, false, false);
        rpaUIData.isTotalPricePerVolumeOverriden = this.isTotalPricePerVolumeValueOverriden(
          newFormattedValue,
          rpaUIData,
        );
        break;
      case rpaCommonVariables.RPA_RETAIL_SALES:
        rpaUIData.rpaData.retailSales = newFormattedValue;
        rpaUIData.isRetailSalesInvalid = this.isInvalid(newFormattedValue, false, false);
        calculateCatPricePerVolume = true;
        break;
      case rpaCommonVariables.RPA_TOTAL_VOLUME_SALES:
        rpaUIData.rpaData.totalVolumeSales = newFormattedValue;
        rpaUIData.isTotalVolumeSalesInvalid = this.isInvalid(newFormattedValue, false, false);
        calculateCatPricePerVolume = true;
        break;
      case rpaCommonVariables.RPA_CAT_PRICE_PER_VOLUME:
        rpaUIData.rpaData.pricePerVolume = newFormattedValue;
        rpaUIData.isCatPricePerVolumeInvalid = this.isInvalid(newFormattedValue, false, false);
        // verify whether value is overriden or not
        rpaUIData.isCatPricePerVolumeOverriden = this.isCatPricePerVolumeValueOverriden(
          newFormattedValue,
          rpaUIData.rpaData.retailSales,
          rpaUIData.rpaData.totalVolumeSales,
        );
        break;
      case rpaCommonVariables.RPA_RELATIVE_PRICE_INDEX:
        rpaUIData.rpaData.relativePriceIndex = newFormattedValue;
        rpaUIData.isRelativePriceIndexInvalid = this.isInvalid(newFormattedValue, false, false);
        // verify whether value is overriden or not
        rpaUIData.rpaData.isRelativePriceIndexOverriden = this.isRawRelativePriceValueOverriden(
          newFormattedValue,
          rpaUIData.totalPricePerVolume,
          rpaUIData.rpaData.pricePerVolume,
        );
        // If RPI value is empty then update the overriden flag to false
        if (isEmpty(newFormattedValue)) {
          rpaUIData.rpaData.isRelativePriceIndexOverriden = false;
        }
        break;
      case rpaCommonVariables.RPA_BELIEVED_PRICE_ELASTICITY:
        rpaUIData.rpaData.believedPriceElasticity = newFormattedValue;
        rpaUIData.isBelievedPriceElasticityInvalid = this.isInvalid(
          newFormattedValue,
          true,
          false,
          rpaCommonVariables.RPA_ELASTICITY_MIN_VALUE,
          rpaCommonVariables.RPA_ELASTICITY_MAX_VALUE,
        );
        // verify whether value is overriden or not
        rpaUIData.isBelievedPriceElasticityOverriden = this.isBelievedPriceElasticityValueOverriden(
          newFormattedValue,
        );
        break;
      case rpaCommonVariables.RPA_FINAL_RELATIVE_PRICE:
        rpaUIData.rpaData.finalRelativePrice = newFormattedValue;
        rpaUIData.isFinalRPAInvalid = this.isInvalidFinalRPA(newFormattedValue);
        // verify whether value is overriden or not
        rpaUIData.isFinalRPAOverriden = this.isFinalRPAValueOverriden(
          newFormattedValue,
          rpaUIData.rpaData.boundedRelativePrice,
        );
        break;
      default:
        break;
    }
    // Calculate category price Per volume
    if (calculateCatPricePerVolume && !rpaUIData.isCatPricePerVolumeOverriden) {
      rpaUIData.rpaData.pricePerVolume = this.calculateCategoryPricePerVolume(
        rpaUIData.rpaData.retailSales,
        rpaUIData.rpaData.totalVolumeSales,
      );
    }
    if (skuDataChanged) {
      // do calculations
      this.calculateTotals(rpaUIData);
    }
    this.updateCalculateButtonFlag(rpaIndex);
  }

  validateBlendedRPAWeights(rpaUIData, index, newFormattedValue) {
    const rpaUIWeight = cloneJson(rpaUIData.rpaData.rpaWeights[index]);
    rpaUIWeight.weight = newFormattedValue;
    rpaUIWeight.isWeightInvalid = this.isInvalid(newFormattedValue, false, true, 0, 100);
    rpaUIData.rpaData.rpaWeights[index] = rpaUIWeight;
  }

  calculateFinalRPAValue(rpaUIData) {
    let finalRPAValue = 0;
    rpaUIData.rpaData.rpaWeights.forEach(rpaUiWeight => {
      finalRPAValue = finalRPAValue + rpaUiWeight.rpaValue * (rpaUiWeight.weight / 100);
    });
    rpaUIData.rpaData.finalRelativePrice = isNaN(finalRPAValue) ? 0 : finalRPAValue;
  }

  updateCalculateButtonFlag(rpaIndex) {
    let showCalculateButton = false;
    const rpaUIData = this.relativePriceAdjustmentsUIData[rpaIndex],
      backupRPAUIData = this.relativePriceAdjustmentUIBackupData[rpaIndex];
    if (rpaUIData.rpaData.adjustmentType === ADJUSTMENT_TYPE.RPA) {
      showCalculateButton =
        this.isValueChanged(rpaUIData.rpaData.skuSource, backupRPAUIData.rpaData.skuSource) ||
        this.isSkuDataDirty(rpaUIData.rpaData.skus, backupRPAUIData.rpaData.skus) ||
        this.isValueChanged(
          backupRPAUIData.rpaData.totalPricePerVolume,
          rpaUIData.rpaData.totalPricePerVolume,
        ) ||
        this.isValueChanged(backupRPAUIData.rpaData.retailSales, rpaUIData.rpaData.retailSales) ||
        this.isValueChanged(
          backupRPAUIData.rpaData.totalVolumeSales,
          rpaUIData.rpaData.totalVolumeSales,
        ) ||
        this.isValueChanged(
          backupRPAUIData.rpaData.pricePerVolume,
          rpaUIData.rpaData.pricePerVolume,
        ) ||
        this.isValueChanged(
          backupRPAUIData.rpaData.relativePriceIndex,
          rpaUIData.rpaData.relativePriceIndex,
        ) ||
        this.isValueChanged(
          backupRPAUIData.rpaData.believedPriceElasticity,
          rpaUIData.rpaData.believedPriceElasticity,
        ) ||
        this.isValueChanged(
          backupRPAUIData.rpaData.finalRelativePrice,
          rpaUIData.rpaData.finalRelativePrice,
        );
    } else {
      showCalculateButton = !compareJson(
        rpaUIData.rpaData.rpaWeights,
        backupRPAUIData.rpaData.rpaWeights,
      );
    }
    rpaUIData.showCalculate = showCalculateButton;
  }

  isSkuDataDirty(skuData, backupSkuData) {
    return !skuData.every((sku, index) => {
      return compareJson(
        this.getSkuDataForCompare(sku),
        this.getSkuDataForCompare(backupSkuData[index]),
      );
    });
  }

  getSkuDataForCompare(sku) {
    const {
      skuId,
      name,
      varietySplit,
      absoluteRetailPrice,
      unitSize,
      unitMeasure,
      pricePerVolume,
    } = sku;
    return {
      skuId,
      name,
      varietySplit,
      absoluteRetailPrice,
      unitSize,
      unitMeasure,
      pricePerVolume,
    };
  }

  /**
   * verifies whether there any changes done to sku table data based on the rpaIndex
   * @param rpaIndex
   */
  hasDirtySkuData(rpaIndex) {
    const rpaData = this.relativePriceAdjustmentsUIData[rpaIndex].rpaData,
      backupRpaData = this.relativePriceAdjustmentUIBackupData[rpaIndex].rpaData;
    return (
      this.isSkuDataDirty(rpaData.skus, backupRpaData.skus) ||
      this.isValueChanged(rpaData.totalPricePerVolume, backupRpaData.totalPricePerVolume)
    );
  }

  blendedAmountValueChanged(event, rpaIndex, index) {
    const newUnMaskedValue = event.target.value.trim();
    const newFormattedValue = this.getFormattedValue(newUnMaskedValue);
    event.target.textContent = newFormattedValue;
    const rpaUIData = this.relativePriceAdjustmentsUIData[rpaIndex];
    this.validateBlendedRPAWeights(rpaUIData, index, newFormattedValue);
    this.updateCalculateButtonFlag(rpaIndex);
  }

  calculateTotals(rpaUIData) {
    let totalVarietySplit = 0.0;
    let totalBlendedPrice = 0.0;
    let totalPricePerVol = 0.0;
    rpaUIData.rpaData.skus.forEach(skuData => {
      totalVarietySplit = totalVarietySplit + (skuData.varietySplit ? skuData.varietySplit : 0);
      totalBlendedPrice =
        totalBlendedPrice +
        (skuData.varietySplit ? skuData.varietySplit : 0) *
          (skuData.absoluteRetailPrice ? skuData.absoluteRetailPrice : 0);
      totalPricePerVol =
        totalPricePerVol +
        (skuData.varietySplit ? skuData.varietySplit : 0) * skuData.pricePerVolume;
    });
    // need total variety split for price per vol calculation
    if (totalVarietySplit > 0) {
      totalBlendedPrice = Number((totalBlendedPrice / totalVarietySplit).toFixed(2));
      totalPricePerVol = Number((totalPricePerVol / totalVarietySplit).toFixed(2));
    }
    this.updateTotalValues(totalVarietySplit, totalBlendedPrice, totalPricePerVol, rpaUIData);
    // make validations here whenever needed
    if (totalVarietySplit > 0) {
      rpaUIData.isTotalVarietySplitInvalid = this.isInvalid(
        totalVarietySplit,
        false,
        true,
        rpaCommonVariables.RPA_TOTAL_CTRILAF_MIN_VALUE,
        rpaCommonVariables.RPA_TOTAL_CTRILAF_MAX_VALUE,
      );
    }
  }

  /*
    Assign total values of sku data to Relative price adjustment data
   */
  updateTotalValues(totalVarietySplit, totalBlendedPrice, totalPricePerVol, rpaUIData) {
    // make empty if no values
    rpaUIData.totalVarietySplit = totalVarietySplit > 0 ? totalVarietySplit : null;
    rpaUIData.totalAbsoluteRetailPrice = totalBlendedPrice > 0 ? totalBlendedPrice : null;
    rpaUIData.totalPricePerVolume = totalPricePerVol > 0 ? totalPricePerVol : null;
    if (!rpaUIData.isTotalPricePerVolumeOverriden) {
      rpaUIData.rpaData.totalPricePerVolume = totalPricePerVol > 0 ? totalPricePerVol : null;
    }
  }

  compareRPANumbers(num1, num2) {
    return Number(num1).toFixed(2) === Number(num2).toFixed(2);
  }

  isTotalPricePerVolumeValueOverriden(totalPricePerVol, rpaUIData) {
    this.calculateTotals(rpaUIData);
    if (this.compareRPANumbers(rpaUIData.totalPricePerVolume, totalPricePerVol)) {
      return false;
    } else {
      rpaUIData.rpaData.totalPricePerVolume = totalPricePerVol;
      return true;
    }
  }

  calculateSkuPricePerVolume(absoluteRetailPrice, unitSize) {
    if (unitSize > 0 && absoluteRetailPrice > 0) {
      return absoluteRetailPrice / unitSize;
    }
  }

  isInvalid(value, allowNegatives = false, acceptZero = true, min = null, max = null): boolean {
    let isInvalid = false;
    isInvalid = isNaN(value) || (allowNegatives ? false : value < 0);
    if (!isInvalid) {
      isInvalid = acceptZero ? false : value === 0;
    }
    if (isNotEmpty(value) && isNotEmpty(min) && isNotEmpty(max)) {
      isInvalid = !(value >= min && value <= max);
    }
    return isInvalid;
  }

  updateSkusData(conceptSku) {
    const skuItem = new RPAUISkuData();
    skuItem.varietySplit = null;
    skuItem.skuId = conceptSku.id;
    skuItem.name = conceptSku.name;
    skuItem.absoluteRetailPrice = Number(conceptSku.retailPrice);
    skuItem.unitSize = conceptSku.unitSize;
    if (skuItem.absoluteRetailPrice && skuItem.unitSize) {
      skuItem.pricePerVolume = Number((skuItem.absoluteRetailPrice / skuItem.unitSize).toFixed(2));
    }
    skuItem.unitMeasure = 'Count';
    return skuItem;
  }

  /**
   * This method compares both old and new values
   * if matches returns false else return true
   * When we have old value as undefined and new value as empty, equals comparison returns true
   * to compare in that condition created below method.
   * @param oldValue
   * @param newValue
   */
  isValueChanged(oldValue, newValue) {
    if (oldValue) {
      return oldValue !== newValue;
    } else {
      return isNotEmpty(newValue);
    }
  }

  isFinalRPAValueOverriden(finalRPA, boundedRPA) {
    if ((isEmpty(boundedRPA) && finalRPA === 1) || finalRPA === boundedRPA) {
      return false;
    } else {
      return true;
    }
  }

  isBelievedPriceElasticityValueOverriden(believedPrice) {
    return believedPrice !== -1;
  }

  isRawRelativePriceValueOverriden(rawRPA, totalPricePerVol, catPricePerVol) {
    const actualRPA = totalPricePerVol / catPricePerVol;
    return !this.compareRPANumbers(actualRPA, rawRPA);
  }

  isCatPricePerVolumeValueOverriden(catPricePerVolume, retailSales, totalVolumeSales) {
    const actualCatPricePerVol = retailSales / totalVolumeSales;
    return !this.compareRPANumbers(actualCatPricePerVol, catPricePerVolume);
  }

  calculateCategoryPricePerVolume(retailSales, totalVolumeSales) {
    if (retailSales > 0 && totalVolumeSales > 0) {
      return retailSales / totalVolumeSales;
    }
  }

  isInvalidFinalRPA(value) {
    let isInvalid = false;
    isInvalid = isNaN(value) || value < 0;
    if (isNotEmpty(value)) {
      isInvalid = !(
        value > rpaCommonVariables.FINAL_RPA_MIN_VALUE &&
        value < rpaCommonVariables.FINAL_RPA_MAX_VALUE
      );
    }
    return isInvalid;
  }

  isRPADirty(rpaIndex) {
    const rpaUiData = this.relativePriceAdjustmentsUIData[rpaIndex],
      backupRpaUiData = this.relativePriceAdjustmentUIBackupData[rpaIndex];
    return (
      rpaUiData.showCalculate ||
      this.isValueChanged(rpaUiData.rpaData.description, backupRpaUiData.rpaData.description) ||
      !compareJson(rpaUiData.rpaData.appliesToEstimates, backupRpaUiData.rpaData.appliesToEstimates)
    );
  }

  saveRPAData() {
    const unSavedRPA = [];
    this.relativePriceAdjustmentsUIData.forEach((rpaUIData, index) => {
      if (this.isRPADirty(index)) {
        /*
        for blended RPA blended amount percentage should be divided by 100
        before saving it to database.
         */
        if (rpaUIData.rpaData.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA) {
          rpaUIData.rpaData.rpaWeights = rpaUIData.rpaData.rpaWeights.filter(
            rpaWeight => rpaWeight.selected,
          );
          this.calculateFinalRPAValue(rpaUIData);
          rpaUIData.rpaData.rpaWeights.forEach(blendedWeight => {
            const weight = blendedWeight.weight;
            blendedWeight.weight = weight > 0 ? weight / 100 : weight;
          });
          rpaUIData.rpaData.finalRelativePrice = Number(
            rpaUIData.rpaData.finalRelativePrice.toFixed(2),
          );
        }
        unSavedRPA.push(rpaUIData.rpaData);
      }
    });
    this.failedRequestData = cloneJson(unSavedRPA);
    this.dispatchUpdateRelativePriceAdjustmentsList(unSavedRPA);
  }

  dispatchUpdateRelativePriceAdjustmentsList(rpaRequestData) {
    this.forecastRunsFacade.dispatch(
      rpaActions.updateRelativePriceAdjustmentsList({
        rpaListRequest: rpaRequestData,
        forecastRunId: this.forecastRunId,
        onCompleteActions: [
          adjustmentActions.fetchAdjustments({ forecastRunId: this.forecastRunId }),
        ],
        forecastId: this.forecastId,
      }),
    );
  }

  // verify whether all the min maz validations are satisfied or not, if not add generate error messages.
  hasValidRPAData() {
    this.nonFormErrorMessages = [];
    this.relativePriceAdjustmentsUIData.forEach((rpa, index) => {
      if (rpa.rpaData.adjustmentType === ADJUSTMENT_TYPE.RPA) {
        // Valid RPA Scenario
        this.validateRPAScenario(rpa, index);
      } else {
        // Valid Blended RPA Scenario
        this.validateBlendedRPAScenario(rpa, index);
      }
    });
    setTimeout(() => {
      this.validateErrorEvent.next(false);
    });
    return this.nonFormErrorMessages.length === 0;
  }

  validateRPAScenario(rpa, index) {
    // verify RPA scenario name
    rpa.isScenarioNameInvalid = this.validateRPAScenarioName(rpa.rpaData.description, index);
    // generate skus specific validations
    rpa.rpaData.skus.forEach(sku => {
      sku.isVarietySplitInvalid = this.generateErrorMessagesForCtrilafColumn(
        sku,
        rpa.rpaData.description,
      );
      sku.isAbsoluteRetailPriceInvalid = this.generatePositiveNumericRequiredMessage(
        sku.absoluteRetailPrice,
        this.translations['app.model.relativePriceAdjustment.absolute.retail.price.label'],
        rpa.rpaData.description,
        true,
        false,
        false,
        sku.name,
      );
      sku.isUnitSizeInvalid = this.generatePositiveNumericRequiredMessage(
        sku.unitSize,
        this.translations['app.model.relativePriceAdjustment.volume.per.pack.label'],
        rpa.rpaData.description,
        true,
        false,
        false,
        sku.name,
      );
    });
    this.relativePriceAdjustmentsUIData[
      index
    ].isTotalVarietySplitInvalid = this.generateErrorMessagesForTotalCtrilafColumn(
      this.relativePriceAdjustmentsUIData[index].totalVarietySplit,
      rpa.rpaData.description,
    );
    this.relativePriceAdjustmentsUIData[
      index
    ].isTotalPricePerVolumeInvalid = this.generatePositiveNumericRequiredMessage(
      this.relativePriceAdjustmentsUIData[index].rpaData.totalPricePerVolume,
      this.translations['app.model.relativePriceAdjustment.price.per.vol.label'],
      rpa.rpaData.description,
      true,
      false,
      false,
      'Total',
    );
    this.relativePriceAdjustmentsUIData[
      index
    ].isRetailSalesInvalid = this.generatePositiveNumericRequiredMessage(
      this.relativePriceAdjustmentsUIData[index].rpaData.retailSales,
      this.translations['app.model.relativePriceAdjustment.category.retail.sales.value.label'],
      rpa.rpaData.description,
      true,
      false,
      false,
    );
    this.relativePriceAdjustmentsUIData[
      index
    ].isTotalVolumeSalesInvalid = this.generatePositiveNumericRequiredMessage(
      this.relativePriceAdjustmentsUIData[index].rpaData.totalVolumeSales,
      this.translations['app.model.relativePriceAdjustment.category.total.volume.sales.label'],
      rpa.rpaData.description,
      true,
      false,
      false,
    );
    this.relativePriceAdjustmentsUIData[
      index
    ].isCatPricePerVolumeInvalid = this.generatePositiveNumericRequiredMessage(
      this.relativePriceAdjustmentsUIData[index].rpaData.pricePerVolume,
      this.translations['app.model.relativePriceAdjustment.category.price.per.volume.label'],
      rpa.rpaData.description,
      true,
      false,
      false,
    );
    this.relativePriceAdjustmentsUIData[
      index
    ].isRelativePriceIndexInvalid = this.generatePositiveNumericRequiredMessage(
      this.relativePriceAdjustmentsUIData[index].rpaData.relativePriceIndex,
      this.translations['app.model.relativePriceAdjustment.relative.price.index.label'],
      rpa.rpaData.description,
      false,
      false,
      false,
    );
    this.relativePriceAdjustmentsUIData[
      index
    ].isBelievedPriceElasticityInvalid = this.generateErrorMessageWithRange(
      this.relativePriceAdjustmentsUIData[index].rpaData.believedPriceElasticity,
      rpaCommonVariables.RPA_ELASTICITY_MIN_VALUE,
      rpaCommonVariables.RPA_ELASTICITY_MAX_VALUE,
      this.translations['app.model.relativePriceAdjustment.believed.price.elasticity.label'],
      rpa.rpaData.description,
    );
    this.relativePriceAdjustmentsUIData[
      index
    ].isFinalRPAInvalid = this.generateErrorMessageWithRange(
      this.relativePriceAdjustmentsUIData[index].rpaData.finalRelativePrice,
      rpaCommonVariables.FINAL_RPA_MIN_VALUE,
      rpaCommonVariables.FINAL_RPA_MAX_VALUE,
      this.translations['app.model.relativePriceAdjustment.final.relative.price.adjustment.label'],
      rpa.rpaData.description,
    );
  }

  validateBlendedRPAScenario(blendedRPA, index) {
    blendedRPA.isScenarioNameInvalid = this.validateRPAScenarioName(
      blendedRPA.rpaData.description,
      index,
    );
    let totalWeights = 0;
    blendedRPA.rpaData.rpaWeights.forEach(blendedWeight => {
      if (blendedWeight.selected) {
        totalWeights = totalWeights + (blendedWeight.weight ? blendedWeight.weight : 0);
        blendedWeight.isWeightInvalid = this.generateErrorMessageForWeights(
          blendedWeight.weight,
          blendedRPA.rpaData.description,
        );
      }
    });
    if (totalWeights !== 100) {
      this.nonFormErrorMessages.push(
        this.translations['app.rpa.blended.total.weights.error.message'].replace(
          rpaCommonVariables.RPA_SCENARIONAME,
          blendedRPA.rpaData.description,
        ),
      );
    }
  }

  generateErrorMessageForWeights(value, rpaScenarioName) {
    let hasInValidData = false;
    if (isNaN(value)) {
      hasInValidData = true;
      const errorMessage = this.translations[
        rpaCommonVariables.RPA_NUMERIC_ERROR_MESSAGE_TRANSLATION
      ]
        .replace(rpaCommonVariables.RPA_FIELD_NAME, 'Blended Amount')
        .replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName);
      this.nonFormErrorMessages.push(errorMessage);
    } else if (isEmpty(value) || !(value >= 0 && value <= 100)) {
      hasInValidData = true;
      const errorMessage = this.translations[
        'app.model.rpa.blended.weight.min.max.validation.error.message'
      ];
      this.nonFormErrorMessages.push(
        errorMessage.replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName),
      );
    }
    return hasInValidData;
  }

  validateRPAScenarioName(scenarioName, index) {
    let isInvalid = false;
    if (validateEmpty(scenarioName)) {
      isInvalid = true;
      this.nonFormErrorMessages.push(
        this.translations['app.model.relativePriceAdjustment.scenario.name.required.message'],
      );
    } else if (!validateAlphaNumeric(scenarioName)) {
      isInvalid = true;
      this.nonFormErrorMessages.push(
        this.translations[
          'app.model.relativePriceAdjustment.scenario.name.invalid.characters.message'
        ].replace(rpaCommonVariables.RPA_SCENARIONAME, scenarioName),
      );
    } else if (!this.validateScenarioNameLength(scenarioName)) {
      isInvalid = true;
      this.nonFormErrorMessages.push(
        this.translations[
          'app.model.relativePriceAdjustment.scenario.name.length.invalid.message'
        ].replace(rpaCommonVariables.RPA_SCENARIONAME, scenarioName),
      );
    } else if (this.validateDuplicateScenarioName(scenarioName, index)) {
      isInvalid = true;
      let errorMessage = this.translations[
        'app.model.relativePriceAdjustment.scenario.name.duplicate.message'
      ];
      errorMessage = errorMessage.replace('{{name}}', scenarioName);
      if (!this.nonFormErrorMessages.includes(errorMessage)) {
        this.nonFormErrorMessages.push(errorMessage);
      }
    }
    return isInvalid;
  }

  validateScenarioNameLength(scenarioName) {
    return scenarioName && scenarioName.length < 50;
  }

  validateDuplicateScenarioName(scenarioName: string, index) {
    return this.relativePriceAdjustmentsUIData.some(
      (rpa, i) =>
        index !== i &&
        rpa.rpaData.description.toLowerCase().trim() === scenarioName.toLowerCase().trim(),
    );
  }

  generatePositiveNumericRequiredMessage(
    value,
    fieldName,
    rpaScenarioName,
    isRequired,
    allowNegatives,
    acceptZero,
    skuname = null,
  ) {
    let hasInValidData;
    if (isNotEmpty(value) && isNaN(value)) {
      hasInValidData = true;
      let errorMessage = skuname
        ? this.translations['app.model.relativePriceAdjustment.error.message.numeric.skus']
        : this.translations[rpaCommonVariables.RPA_NUMERIC_ERROR_MESSAGE_TRANSLATION];
      if (skuname) {
        errorMessage = errorMessage.replace(rpaCommonVariables.RPA_SKU_NAME, skuname);
      }
      errorMessage = errorMessage
        .replace(rpaCommonVariables.RPA_FIELD_NAME, fieldName)
        .replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName);
      this.nonFormErrorMessages.push(errorMessage);
    } else if (
      (isEmpty(value) && isRequired) ||
      (!allowNegatives && value < 0) ||
      (isNotEmpty(value) && (!acceptZero && value === 0))
    ) {
      hasInValidData = true;
      this.generateErrorMessageForPositiveRequiredField(
        rpaScenarioName,
        fieldName,
        skuname,
        acceptZero,
      );
    }
    return hasInValidData;
  }

  generateErrorMessageForPositiveRequiredField(rpaScenarioName, fieldName, skuname, acceptZero) {
    let errorMessage = skuname
      ? acceptZero
        ? this.translations[
            'app.model.relativePriceAdjustment.skus.zero.positive.numeric.error.message'
          ]
        : this.translations['app.model.relativePriceAdjustment.skus.positive.numeric.error.message']
      : acceptZero
      ? this.translations['app.model.relativePriceAdjustment.zero.positve.numeric.error.message']
      : this.translations['app.model.relativePriceAdjustment.positve.numeric.error.message'];
    if (skuname) {
      errorMessage = errorMessage.replace(rpaCommonVariables.RPA_SKU_NAME, skuname);
    }
    errorMessage = errorMessage
      .replace(rpaCommonVariables.RPA_FIELD_NAME, fieldName)
      .replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName);
    this.nonFormErrorMessages.push(errorMessage);
  }

  generateErrorMessagesForCtrilafColumn(sku, rpaScenarioName) {
    let hasInValidData;
    const ctrilafLabel = this.translations['app.model.relativePriceAdjustment.ctrilaf.label'];
    if (isNaN(sku.varietySplit)) {
      hasInValidData = true;
      const errorMessage = this.translations[
        'app.model.relativePriceAdjustment.error.message.numeric.skus'
      ]
        .replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName)
        .replace(rpaCommonVariables.RPA_SKU_NAME, sku.name)
        .replace(rpaCommonVariables.RPA_FIELD_NAME, ctrilafLabel);
      this.nonFormErrorMessages.push(errorMessage);
    } else if (
      isEmpty(sku.varietySplit) ||
      !(
        sku.varietySplit >= rpaCommonVariables.RPA_CTRILAF_MIN_VALUE &&
        sku.varietySplit <= rpaCommonVariables.RPA_CTRILAF_MAX_VALUE
      )
    ) {
      hasInValidData = true;
      const errorMessage = this.translations[
        'app.model.relativePriceAdjustment.skus.min.max.validation.error.message'
      ]
        .replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName)
        .replace(rpaCommonVariables.RPA_SKU_NAME, sku.name)
        .replace(rpaCommonVariables.RPA_COLUMN_NAME, ctrilafLabel)
        .replace('{min}', rpaCommonVariables.RPA_CTRILAF_MIN_VALUE)
        .replace('{max}', rpaCommonVariables.RPA_CTRILAF_MAX_VALUE);
      this.nonFormErrorMessages.push(errorMessage);
    }
    return hasInValidData;
  }

  generateErrorMessageWithRange(value, minValue, maxValue, fieldName, rpaScenarioName) {
    let hasInValidData;
    if (isNaN(value)) {
      hasInValidData = true;
      const errorMessage = this.translations[
        rpaCommonVariables.RPA_NUMERIC_ERROR_MESSAGE_TRANSLATION
      ]
        .replace(rpaCommonVariables.RPA_FIELD_NAME, fieldName)
        .replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName);
      this.nonFormErrorMessages.push(errorMessage);
    } else if (isEmpty(value) || !(value > minValue && value < maxValue)) {
      hasInValidData = true;
      const errorMessage =
        fieldName ===
        this.translations['app.model.relativePriceAdjustment.final.relative.price.adjustment.label']
          ? this.translations[
              'app.model.relativePriceAdjustment.final.RPA.min.max.validation.error.message'
            ]
          : this.translations[
              'app.model.relativePriceAdjustment.elasticity.min.max.validation.error.message'
            ];
      this.nonFormErrorMessages.push(
        errorMessage.replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName),
      );
    }
    return hasInValidData;
  }

  generateErrorMessagesForTotalCtrilafColumn(value, rpaScenarioName) {
    let hasInValidData;
    if (
      !(
        value >= rpaCommonVariables.RPA_TOTAL_CTRILAF_MIN_VALUE &&
        value <= rpaCommonVariables.RPA_TOTAL_CTRILAF_MAX_VALUE
      )
    ) {
      hasInValidData = true;
      const errorMessage = this.translations[
        'app.model.relativePriceAdjustment.skus.min.max.validation.error.message'
      ]
        .replace('{skuName}', 'Total')
        .replace(
          '{columnName}',
          this.translations['app.model.relativePriceAdjustment.ctrilaf.label'],
        )
        .replace(rpaCommonVariables.RPA_SCENARIONAME, rpaScenarioName)
        .replace('{min}', rpaCommonVariables.RPA_TOTAL_CTRILAF_MIN_VALUE)
        .replace('{max}', rpaCommonVariables.RPA_TOTAL_CTRILAF_MAX_VALUE);
      this.nonFormErrorMessages.push(errorMessage);
    }
    return hasInValidData;
  }

  addRPAScenario() {
    const newRPA = this.generateNewRPA();
    const defaultRPAUiData = new RelativePriceAdjustmentUIData();
    defaultRPAUiData.rpaData = newRPA;
    // insert it before blended RPAs
    const rpaCount = this.findRPACount();
    this.relativePriceAdjustmentsUIData.splice(rpaCount, 0, cloneJson(defaultRPAUiData));
    this.relativePriceAdjustmentUIBackupData.splice(rpaCount, 0, cloneJson(defaultRPAUiData));
    // update sku data
    this.estimateSelected(this.dropDownItems[0] ? this.dropDownItems[0] : '', rpaCount);
  }

  /**
   * returns count of number of RPA scenarios with adjustment type equal to 'relativePriceAdjustment'
   */
  findRPACount() {
    return this.relativePriceAdjustmentsUIData.filter(
      rpa => rpa.rpaData.adjustmentType === ADJUSTMENT_TYPE.RPA,
    ).length;
  }

  generateNewRPA() {
    const defaultRpa = new RelativePriceAdjustmentUI({});
    defaultRpa.estimateResultObjKey = rpaCommonVariables.RELATIVE_PRICE_ADJUSTMENT_ROW_KEY;
    // generate RPA name dynamically
    defaultRpa.adjustmentType = ADJUSTMENT_TYPE.RPA;
    defaultRpa.generatedScenarioName = this.generateRPAScenarioName();
    defaultRpa.description = defaultRpa.generatedScenarioName;
    defaultRpa.forecastRun = this.forecastRun;
    defaultRpa.believedPriceElasticity = rpaCommonVariables.RPA_BELIEVED_PRICE_DEFAULT_VALUE;
    defaultRpa.finalRelativePrice = rpaCommonVariables.FINAL_RPA_DEFAULT_VALUE;
    defaultRpa.skuSource = this.dropDownItems[0] ? this.dropDownItems[0].value : '';
    defaultRpa.skus = [];
    return defaultRpa;
  }

  isFormDirty() {
    let isDirty = false;
    this.relativePriceAdjustmentsUIData.forEach((rpaUIData, index) => {
      if (!isDirty) {
        isDirty = !compareJson(
          rpaUIData.rpaData,
          this.relativePriceAdjustmentUIBackupData[index].rpaData,
        );
      }
    });
    return isDirty;
  }

  onDropdownClosed(dropdownItem: DropDownItem[], rpaIndex) {
    if (isNotEmpty(dropdownItem)) {
      const appliesToEstimates = dropdownItem.filter(item => item.selected).map(item => item.value);
      this.relativePriceAdjustmentsUIData[rpaIndex].rpaData.appliesToEstimates = appliesToEstimates;
    }
    this.findAppliesToEstimatesAvailableData();
  }

  unitOfMeasureChanged(value, rpaIndex) {
    if (
      this.relativePriceAdjustmentsUIData[rpaIndex].rpaData.skus &&
      this.relativePriceAdjustmentsUIData[rpaIndex].rpaData.skus.length > 0
    ) {
      this.relativePriceAdjustmentsUIData[rpaIndex].rpaData.skus.forEach(skuItem => {
        skuItem.unitMeasure = value;
      });
    }
    this.updateCalculateButtonFlag(rpaIndex);
  }

  onScenarioNameChanged($event, rpaIndex) {
    this.relativePriceAdjustmentsUIData[rpaIndex].rpaData.description = $event;
  }

  hasOverridesForRPA(rpaIndex) {
    const rpaUIData = this.relativePriceAdjustmentsUIData[rpaIndex];
    return (
      rpaUIData.isTotalPricePerVolumeOverriden ||
      rpaUIData.isCatPricePerVolumeOverriden ||
      rpaUIData.rpaData.isRelativePriceIndexOverriden ||
      rpaUIData.isBelievedPriceElasticityOverriden ||
      rpaUIData.isFinalRPAOverriden
    );
  }

  revertOverrides(rpaIndex) {
    const rpaUIData = this.relativePriceAdjustmentsUIData[rpaIndex];
    // reset all overrides data
    if (rpaUIData.isTotalPricePerVolumeOverriden) {
      rpaUIData.isTotalPricePerVolumeOverriden = false;
      rpaUIData.rpaData.totalPricePerVolume = rpaUIData.totalPricePerVolume;
    }
    if (rpaUIData.isCatPricePerVolumeOverriden) {
      rpaUIData.rpaData.pricePerVolume = this.calculateCategoryPricePerVolume(
        rpaUIData.rpaData.retailSales,
        rpaUIData.rpaData.totalVolumeSales,
      );
    }
    rpaUIData.rpaData.isRelativePriceIndexOverriden = false;
    rpaUIData.rpaData.relativePriceIndex = null;
    rpaUIData.rpaData.believedPriceElasticity = -1;
    rpaUIData.rpaData.finalRelativePrice = rpaUIData.rpaData.boundedRelativePrice;
    this.updatePersistDataFlag(rpaIndex);
    this.failedRequestData = cloneJson(rpaUIData.rpaData);
    this.dispatchUpdateRelativePriceAdjustment(rpaUIData.rpaData);
  }

  dispatchUpdateRelativePriceAdjustment(requestData) {
    this.forecastRunsFacade.dispatch(
      rpaActions.updateRelativePriceAdjustment({
        rpaRequest: requestData,
        forecastRunId: this.forecastRunId,
        calculate: true,
        onCompleteActions: [
          adjustmentActions.fetchAdjustments({ forecastRunId: this.forecastRunId }),
        ],
        forecastId: this.forecastId,
      }),
    );
  }

  updatePersistDataFlag(rpaIndex) {
    this.relativePriceAdjustmentsUIData.forEach((rpaUIData, index) => {
      if (rpaIndex !== index && this.isRPADirty(index)) {
        rpaUIData.persistData = true;
      }
    });
  }

  deleteRPAScenarioFromUIData(rpaIndex) {
    // delete from ui and backup data
    this.relativePriceAdjustmentsUIData.splice(rpaIndex, 1);
    this.relativePriceAdjustmentUIBackupData.splice(rpaIndex, 1);
    if (this.relativePriceAdjustmentsUIData.length === 0) {
      this.addRPAScenario();
    }
  }

  deleteRPAScenario(rpaIndex) {
    this.updatePersistDataFlag(rpaIndex);
    this.forecastRunsFacade.dispatch(
      rpaActions.deleteRelativePriceAdjustment({
        rpa: this.relativePriceAdjustmentsUIData[rpaIndex].rpaData,
        forecastRunId: this.forecastRunId,
        onCompleteActions: [
          adjustmentActions.fetchAdjustments({ forecastRunId: this.forecastRunId }),
        ],
        forecastId: this.forecastId,
      }),
    );
  }

  duplicateRPAScenario(rpaIndex, rpaAdjustment = true) {
    const rpaData = this.relativePriceAdjustmentsUIData[rpaIndex].rpaData;
    const duplicateRPA = new RelativePriceAdjustment({});
    const allNames = this.relativePriceAdjustmentsUIData.map(rpa => rpa.rpaData.description);
    duplicateRPA.description = getDuplicateName(
      this.relativePriceAdjustmentUIBackupData[rpaIndex].rpaData.description,
      allNames,
    );
    duplicateRPA.id = rpaData.id;
    duplicateRPA.adjustmentType = rpaAdjustment ? ADJUSTMENT_TYPE.RPA : ADJUSTMENT_TYPE.BLENDED_RPA;
    /*
      While duplicating we need to persist data for duplicating scenario if there are any,
      so sending the index as -1
     */
    this.updatePersistDataFlag(-1);
    this.isDuplicateRPAScenario = duplicateRPA.adjustmentType;
    this.forecastRunsFacade.dispatch(
      rpaActions.createRelativePriceAdjustment({
        forecastRunId: this.forecastRunId,
        rpaRequest: duplicateRPA,
        onCompleteActions: [
          adjustmentActions.fetchAdjustments({ forecastRunId: this.forecastRunId }),
        ],
        forecastId: this.forecastId,
      }),
    );
  }

  resetRPAData() {
    this.relativePriceAdjustmentsUIData = [];
    this.relativePriceAdjustmentUIBackupData = [];
    this.nonFormErrorMessages = [];
  }

  /*
    it is used to send data when recalculate button clicked
    based on the operation failed, will trigger respective API call.
   */
  recalculateRPAData() {
    if (isNotEmpty(this.failedRequestData)) {
      if (this.failedRequestData instanceof Array) {
        this.dispatchUpdateRelativePriceAdjustmentsList(this.failedRequestData);
      } else {
        this.dispatchUpdateRelativePriceAdjustment(this.failedRequestData);
      }
    } else {
      this.saveRPAData();
    }
  }

  addNewBlendedRPAScenario() {
    const blendedRPAUIData = new BlendedRPAUIData();
    const blendedRPA = new BlendedRPAUI({});
    blendedRPA.adjustmentType = ADJUSTMENT_TYPE.BLENDED_RPA;
    blendedRPA.forecastRun = this.forecastRun;
    blendedRPA.description = this.generateRPAScenarioName();
    blendedRPA.generatedScenarioName = blendedRPA.description;
    blendedRPA.finalRelativePrice = 0;
    blendedRPA.rpaWeights = this.getSavedRelativePriceAdjustments().map(rpaUIData => {
      return {
        relativePriceAdjustmentId: rpaUIData.id,
        relativePriceAdjustmentName: rpaUIData.description,
        weight: 0,
        selected: false,
        rpaValue: rpaUIData.finalRelativePrice,
        isRPAValueOverriden: this.isFinalRPAValueOverriden(
          rpaUIData.finalRelativePrice,
          rpaUIData.boundedRelativePrice,
        ),
        isWeightInvalid: false,
      };
    });
    blendedRPAUIData.rpaData = blendedRPA;
    this.relativePriceAdjustmentsUIData.push(cloneJson(blendedRPAUIData));
    this.relativePriceAdjustmentUIBackupData.push(cloneJson(blendedRPAUIData));
  }

  onScenarioCheckBoxUpdated(rpaIndex, scenarioIndex) {
    const blendedRPAUIData = cloneJson(this.relativePriceAdjustmentsUIData[rpaIndex]);
    blendedRPAUIData.rpaData.rpaWeights[scenarioIndex].selected = !blendedRPAUIData.rpaData
      .rpaWeights[scenarioIndex].selected;
    blendedRPAUIData.rpaData.rpaWeights[scenarioIndex].weight = null;
    blendedRPAUIData.rpaData.rpaWeights[scenarioIndex].isWeightInvalid = false;
    this.relativePriceAdjustmentsUIData[rpaIndex] = blendedRPAUIData;
    this.updateCalculateButtonFlag(rpaIndex);
  }

  isRPAPresentInBlendedScenario(rpaIndex) {
    const currentRPAId = this.relativePriceAdjustmentsUIData[rpaIndex].rpaData.id;
    let present = false;
    const savedBlendedRPA = this.relativePriceAdjustments.filter(
      rpa => rpa.adjustmentType === ADJUSTMENT_TYPE.BLENDED_RPA,
    );
    if (savedBlendedRPA) {
      savedBlendedRPA.forEach(blendedRPA => {
        if (!present) {
          blendedRPA.rpaWeights.forEach(weight => {
            if (weight.relativePriceAdjustmentId === currentRPAId) {
              present = true;
            }
          });
        }
      });
    }
    return present;
  }

  /*
   This method creates Applies to estimates dropdown data for each rpa scenario
   which will be supplied to multi-select-dropdown to show them in modal
   */
  updateApplyToEstimatesDropdownData(rpaData) {
    return this.commonAppliesToEstimateData.map((estimate, index) => {
      return {
        label: estimate.name,
        value: estimate.id,
        marketingPlanId: estimate.marketingPlanId,
        selected: rpaData
          ? rpaData.appliesToEstimates && rpaData.appliesToEstimates.includes(estimate.id)
          : false,
        disabled: this.disableEstimateForRPA(estimate, rpaData) || this.isEstimateLocked(estimate.id),
        disabledTooltipText: this.disableEstimateForRPA(estimate, rpaData)
          ? this.translations['app.modal.rpa.applies.to.estimate.disabled.tooltip'].replace(
              RPA_SCENARIONAME,
              estimate.alreadyAppliedAdjustmentName,
            )
          : (this.isEstimateLocked(estimate.id)?this.translations['app.forecast.runs.adjustment.estimate.locked.tooltip']:''),
      };
    });
  }

  disableEstimateForRPA(estimate, rpaData) {
    return estimate.alreadyApplied && estimate.alreadyAppliedAdjustmentName !== rpaData.description;
  }

  isEstimateLocked(estimateId) {
    return this.lockedEstimateIds && this.lockedEstimateIds.includes(estimateId) && !this.isDeliverableUnlocked;
  }

  isDeliveredAndUnlocked(estimateId){
    return this.lockedEstimateIds && this.lockedEstimateIds.includes(estimateId) && this.isDeliverableUnlocked;
  }
}
