import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@app/shared/shared.module';
import { OrigamiModule } from '@codebakery/origami';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreModule } from '@src/app/core/core.module';
import { environment } from '../environments/environment';
import { TranslationModule } from '../translation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomSerializer, effects, reducers } from './_store';
import { HttpClientModule } from '@angular/common/http';
import { EnvService } from '@app/core/services/env/env.service';
import { DiscardChangesModalComponent } from '@app/shared/components/modal/templates/discard-changes-modal/discard-changes-modal.component';
import { LoggerType, NgxComponentsConfig, NgxComponentsModule } from '@basesstudio/ngx-components';
import { PortalModule } from '@angular/cdk/portal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationBarModule } from '@app/ngx-components/components/application-bar';
import { BrandBarModule, LogoutDialogComponent } from '@app/ngx-components/components/brand-bar';
import { DistributionAutoBuildModalComponent } from './main/marketing-plans/components/distribution-and-trade/distribution-channel/line-and-sku-distribution-table/distribution-auto-build-modal/distribution-auto-build-modal.component';
import { AdvertisingRevertModalComponent } from '@app/main/marketing-plans/components/advertising/advertising-revert-modal/advertising-revert-modal.component';
import { EditMarketingPlanModalComponent } from './main/marketing-plans/components/marketing-plan/edit-marketing-plan-modal/edit-marketing-plan-modal.component';
import { ErrorHandlerService } from '@app/core/services/application-insights/error-handler.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export function loadConfigurations(envService: EnvService) {
  return () => envService.getConfigs();
}

/**
 * NgxConfiguration configuration.
 */
const ngxConfig: NgxComponentsConfig = {
  moduleName: 'APP',
  logger: {
    type: LoggerType.CONSOLE,
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    NgxComponentsModule.forRoot(ngxConfig),
    OrigamiModule,
    TranslationModule,
    CoreModule.forRoot(),
    PortalModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    ApplicationBarModule,
    BrandBarModule,
    MatProgressSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurations,
      deps: [EnvService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  entryComponents: [
    DiscardChangesModalComponent,
    LogoutDialogComponent,
    AdvertisingRevertModalComponent,
    DistributionAutoBuildModalComponent,
    EditMarketingPlanModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
