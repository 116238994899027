<div class="message-prompt" [ngClass]="data.type">
    <div *ngIf="data.type==='success'" class="align success-bg">
        <mat-icon class="succes">done</mat-icon>
    </div>
    <div *ngIf="data.type==='warning'" class="align warn-bg">
        <img src="assets/images/warning.svg" class="warn-yellow">
    </div>
    <div *ngIf="data.type==='error'" class="align error-bg">
        <img src="assets/images/warning.svg" class="error-red">
    </div>
    <div class="message">
        <span>{{ data.text }}</span>
    </div>
    <span *ngIf="data.type==='error'" class="close-btn">
        <button mat-icon-button (click)="onClose()" class="gap-left-xs"><span
                class="sif sif-12 sif-delete sif-gray"></span></button>
    </span>
</div>